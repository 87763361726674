import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstrumentContributionDialogComponent } from './instrument-contribution-dialog.component';
// ────────────────────────────────────────────────────────────────────────────────
// Material
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
// Grid module ────────────────────────────────────────────────────────────────────
import { FlexLayoutModule } from '@angular/flex-layout';
// ────────────────────────────────────────────────────────────────────────────────
// Forms Module
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
// Instruments form module
import { InstrumentFormModule } from 'src/app/shared/modules/instrument-form/instrument-form.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    // Material
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    // Layout
    FlexLayoutModule,
    // Forms
    ReactiveFormsModule,
    FormsModule,
    // Instrument form
    InstrumentFormModule,
    // Translations
    TranslateModule.forChild(),
  ],
  declarations: [InstrumentContributionDialogComponent]
})
export class InstrumentContributionDialogModule { }
