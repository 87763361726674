import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GeneralHelpers } from '../../helpers/general/general.helper';
import {API_BASE_V1, API_BASE_V2, CATALOG, SHOW_OWNED_TRAYS} from '../../constants/general.constants';
import { delay, Observable, retry, retryWhen } from 'rxjs';
import { Tray, TrayResponse } from '../../interfaces/tray.interface';

const TRAY = 'tray';
const HUB = 'hub';
const ASSEMBLY = 'assembly';
const IMPORT = 'import';

@Injectable({
  providedIn: 'root'
})
export class TrayService {

  public organizationFilter: boolean = !!localStorage.getItem(SHOW_OWNED_TRAYS);

  constructor(private http: HttpClient) { }

  // ────────────────────────────────────────────────────────────────────────────────
  // Create tray

  create(body: any): Observable<any> {
    (body as any) = GeneralHelpers.toMultipartFormData(body);
    return this.http.post(
      `${API_BASE_V2}${HUB}/${CATALOG}/${TRAY}/`,
      body
    );
  }

  createByImport(body: any): Observable<any> {
    (body as any) = GeneralHelpers.toMultipartFormData(body);
    return this.http.post(
      `${API_BASE_V2}${HUB}/${CATALOG}/${TRAY}/${ASSEMBLY}/${IMPORT}/`,
      body
    );
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Read
  // "https://dev-api.layerjot.com/api/1.0/hub/catalog/tray/?is_owned_trays=false&limit=10&offset=10"

  get(
    offset: number = 0,
    limit: number = 25,
    ordering: string = '',
    search: string = '',
    is_owned_trays: boolean = false
  ): Observable<TrayResponse> {
    const options = {
      params: GeneralHelpers.getParams({ offset, limit, ordering, search, is_owned_trays }),
    };
    // https://dev-api.layerjot.com/api/1.0/HUB/catalog/tray/?is_owned_trays=false
    return this.http.get<TrayResponse>(
      `${API_BASE_V2}${HUB}/${CATALOG}/${TRAY}/`, options
    );
  }

  getWithRetry(
    offset: number = 0,
    limit: number = 25,
    ordering: string = '',
    term: string = '',
    is_owned_trays: boolean = false
  ): Observable<TrayResponse> {
    const options = {
      params: GeneralHelpers.getParams({ offset, limit, ordering, term, is_owned_trays }),
    };
    // https://dev-api.layerjot.com/api/1.0/HUB/catalog/tray/?is_owned_trays=false
    return this.http.get<TrayResponse>(
      `${API_BASE_V2}${HUB}/${CATALOG}/${TRAY}/`, options
    ).pipe(delay(3e3), retry());
  }

  getPublic(
    offset: number = 0,
    limit: number = 25,
    ordering: string = '',
    term: string = '',
    is_owned_trays: boolean = false
  ): Observable<TrayResponse> {
    const options = {
      params: GeneralHelpers.getParams({ offset, limit, ordering, term, is_owned_trays }),
    };
    return this.http.get<TrayResponse>(
      `${API_BASE_V1}${CATALOG}/${TRAY}/`, options
    );
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Get by id

  getById(
    id: string | number,
  ): Observable<Tray> {
    return this.http.get<Tray>(
      `${API_BASE_V2}${HUB}/${CATALOG}/${TRAY}/${id}/`
    );
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Update

  update(id: string | any, data: Tray): Observable<Object> {
    (data as any) = GeneralHelpers.toMultipartFormData(data);
    return this.http.put<Tray>(
      `${API_BASE_V2}${HUB}/${CATALOG}/${TRAY}/${id}/`,
      data
    );
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Delete

  delete(id: string) {
    return this.http.delete(
      `${API_BASE_V2}${HUB}/${CATALOG}/${TRAY}/${id}/`
    );
  }

}
