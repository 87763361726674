<div class="invite">
  <button mat-button class="close" (click)="cancel()">
    <mat-icon>close</mat-icon>
  </button>
  <div class="invite_container">
    <div class="invite_title">Invite a team member</div>
    <div class="invite_desc">To add a team member, simply share the following code with them:</div>
    <div class="invite_code">
      <div class="invite_code-label">Invitation code:</div>
      <div class="invite_code-value">{{ organizationsService.inviteCode }}</div>
      <div class="invite_code-action">
        <button
          mat-icon-button
          matSuffix
          aria-label="Copy"
          [cdkCopyToClipboard]="organizationsService.getInviteLink()"
          (click)="dataCopied()"
          [color]="copyColor"
        >
          <mat-icon *ngIf="copyColor === 'primary'">content_copy</mat-icon>
          <mat-icon *ngIf="copyColor !== 'primary'">check</mat-icon>
        </button>
      </div>
    </div>
    <div class="invite_preview">
      <img src="assets/images/invite.jpg" width="221">
    </div>
  </div>
</div>
