<mat-card appearance="outlined" class="instrument"
  [ngClass]="{ 'recongized': instrumentData.is_visual_recognition_ready, 'is-selected': instrumentData.isSelected }"
>
  <mat-card-content>
    <div fxLayout="row" fxLayoutAlign="space-between stretch">
      <div class="instrument-description" fxFlex="50">
        <ng-scrollbar>
          <p>
            {{ instrumentData.name }}
          </p>
        </ng-scrollbar>
      </div>
      <div
        class="instrument-image"
        fxFlex="50"
      >
        <div class="image-wrap">
          <img [src]="mainImage" onerror="this.onerror=null;this.src='assets/images/no-image-b.jpg';" (error)="mainImage = ''" *ngIf="mainImage">
          <img src="assets/images/no-image-b.jpg" *ngIf="!mainImage">
        </div>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
      <p class="mb-0">
        <strong>{{ instrumentData.vendor?.name }}</strong>
      </p>
      <button class="slim" mat-button color="primary">{{ instrumentData.sku }}</button>
    </div>
  </mat-card-content>
</mat-card>
