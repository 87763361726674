import { environment as env } from '../../../environments/environment';
import { Environment } from '../interfaces/environment.interface';
import {
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

// ─────────────────────────────────────────────────────────────────────────────
// Base urls

export const API_BASE_V1 = (env as Environment).apiUrlV1;

export const API_BASE_V2 = (env as Environment).apiUrlV2;

// ─────────────────────────────────────────────────────────────────────────────
// Urls parts

export const ADMIN = 'admin';

export const CATALOG = 'catalog';

export const INSTRUMENT = "instrument";
export const INSTRUMENTS = "instruments"

export const ORGANIZATION = "organization";

export const HUB = "hub";

export const TRAY = "tray";

// ─────────────────────────────────────────────────────────────────────────────
// Local storage names

export const ORGANIZATION_UUID = 'organization_uuid';

export const ACTIVE_SECTION_UUID = 'active_section_uuid';

export const ACTIVE_TRAY_UUID = 'active_tray_uuid';

export const USER_ORGANIZATION_LIST = 'user_organization_list';

export const USER_LANGUAGE = 'user_language';

export const SHOW_OWNED_TRAYS = 'show_owned_trays';

// ─────────────────────────────────────────────────────────────────────────────
// Header error

export const ORGANIZATION_ERROR = 'LJ-User-Organization';

// ─────────────────────────────────────────────────────────────────────────────
// Activation Code
export const ACTIVATION_CODE = 'activation_code';
export const ACTIVATION_CODE_HASH = 'h';
export const ACTIVATION_CODE_TIME = 't';

// Return URL on Login
export const LOGIN_RETURN_PATH = 'return_path';

// ─────────────────────────────────────────────────────────────────────────────
// Roles
export const GUEST = 'GUEST';
export const SUPER_USER = 'superuser';
export const ORGANIZATION_ADMIN = 'organization_admin';
export const ORGANIZATION_EDITOR = 'organization_editor';
export const PUBLIC_CONTRIBUTOR = 'public_contribution';
export const STAFF = 'staff';
// ─────────────────────────────────────────────────────────────────────────────

export const DARK_BACKGROUND_ROUTES = ['/login', '/home', '/'];
export const NO_HEADER_AND_FOOTER_ROUTES = ['/entry-point', '/list/print'];
export const TUTOR_ROUTES = ['/list', '/list/print', '/builder'];

export const USER_ROLES = {
  is_superuser: SUPER_USER,
  is_organization_admin: ORGANIZATION_ADMIN,
  is_organization_editor: ORGANIZATION_EDITOR,
  is_public_contribution: PUBLIC_CONTRIBUTOR,
  is_staff: STAFF
};

// ─────────────────────────────────────────────────────────────────────────────
// First item in array will be set as a default role
// IF role cannot be identified
export const TEAM_ROLES = [
  { value: 0, viewValue: 'Member', key: '', },
  { value: 1, viewValue: 'Editor', key: 'is_organization_editor', },
  { value: 2, viewValue: 'Admin', key: 'is_organization_admin', }
];
export const INSTRUMENTS_VISIBILITY = [
  { value: true, viewValue: 'Public', key: 'public', },
  { value: false, viewValue: 'Private', key: 'private', },
];

// ─────────────────────────────────────────────────────────────────────────────
// Instrument buk update

/**
 *  1 which designates Approve,
 * 2 which designates Reject,
 * 3 which designates Approve scans,
 * 4 which designates Approve notes,
 * 5 which designates Make public,
 * 6 which designates Make private,
 * 7 which designates Make public notes,
 * 8 which designates Make private notes
 */

export const INSTRUMENT_BULK_STATUSES = [
  {
    viewValue: 'Approve instruments',
    value: 1,
  },
  {
    viewValue: 'Reject instruments',
    value: 2,
  },
  {
    viewValue: 'Make public',
    value: 5,
  },
  {
    viewValue: 'Make private',
    value: 6,
  },
  {
    viewValue: 'Approve all scans',
    value: 3,
  },
  {
    viewValue: 'Approve all notes',
    value: 4,
  },
  // {
  //   viewValue: 'Make public notes',
  //   value: 7,
  // },
  // {
  //   viewValue: 'Make private notes',
  //   value: 8,
  // },
]

export const INSTRUMENT_STATUSES = [
  {
    viewValue: 'New',
    value: 0,
  },
  {
    viewValue: 'Awaiting Approval',
    value: 1,
  },
  {
    viewValue: 'Approved',
    value: 2,
  },
  {
    viewValue: 'Rejected',
    value: 3,
  },
]
export const ERROR_MESSAGE_CLASS = 'error-message';

export const SUCCESS_MESSAGE_CLASS = 'success-message';

export const ERROR_MESSAGE_HORIZONTAL_POSITION: MatSnackBarHorizontalPosition =
  'right';

export const ERROR_MESSAGE_VERTICAL_POSITION: MatSnackBarVerticalPosition =
  'bottom';

export const SUCCESS_MESSAGE_VERTICAL_POSITION: MatSnackBarVerticalPosition =
  'top';

export const MESSAGE_TIME = 5e3;

export const SHOW_SUB_MENU_ROUTES = ['/my-trays', '/reports', '/organization', '/team', '/list', '/plans', '/settings']
export const MINOR_SET_ROUTE = ['/builder']

export const INSTRUMENT_GET_PARAMS = {
  offset: 0,
  limit: 25,
  ordering: null,
  search: null,
  is_pagination: true,
  status: null,
  has_image: null,
  organization_uuid: '',
  sku: []
}

//External links

export const IMPORT_TRAY_EXAMPLE = 'https://a419302b-97f4-4493-ac26-380319cca880.usrfiles.com/ugd/a41930_c50728950c2e41a38c90847879a0dddd.xlsx';
