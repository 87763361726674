<div class="container">
  <div class="org-container">
    <div class="org-header">Captures per org</div>
    <div class="org-row" [ngClass]="{'active': org.uuid === activeOrg}" *ngFor="let org of orgList">
      <span class="org-name" (click)="setActiveOrg(org)">{{ org.name }} <span class="captures-number">({{ org?.captured_files_count ?? 0 }})</span></span>
    </div>
  </div>
  <div class="drag-container">
    <div class="drag-header">Approved <span class="captures-number">({{ approvedList.length }})</span></div>
    <div class="drag-body"
         cdkDropList
         cdkDropListOrientation="mixed"
         id="approvedList"
         #approved="cdkDropList"
         [cdkDropListData]="approvedList"
         [cdkDropListConnectedTo]="[awaiting,rejected]"
         (cdkDropListDropped)="drop($event)"
    >
      <div class="drag-photo" *ngFor="let photo of approvedList; let i = index" cdkDrag (click)="showPreview(photo)" (cdkDragStarted)="dragRowStart(photo)" (cdkDragEnded)="dragRowEnd(photo)" [cdkDragData]="photo">
        <div class="drag-photo_wrap">
          <img src="{{photo.url}}" alt="" width="100">
        </div>
      </div>
    </div>
  </div>
  <div class="drag-container">
    <div class="drag-header">Awaiting approval <span class="captures-number">({{ awaitingList.length }})</span></div>
    <div class="drag-body"
         cdkDropList
         cdkDropListOrientation="mixed"
         id="awaitingList"
         #awaiting="cdkDropList"
         [cdkDropListData]="awaitingList"
         [cdkDropListConnectedTo]="[approved,rejected]"
         (cdkDropListDropped)="drop($event)">
      <div class="drag-photo" *ngFor="let photo of awaitingList; let i = index" cdkDrag (click)="showPreview(photo)" (cdkDragStarted)="dragRowStart(photo)" (cdkDragEnded)="dragRowEnd(photo)" [cdkDragData]="photo">
        <div class="drag-photo_wrap">
          <img src="{{photo.url}}" alt="" width="100">
        </div>
      </div>
    </div>
  </div>
  <div class="drag-container">
    <div class="drag-header">Rejected <span class="captures-number">({{ rejectedList.length }})</span></div>
    <div class="drag-body"
         cdkDropList
         cdkDropListOrientation="mixed"
         id="rejectedList"
         #rejected="cdkDropList"
         [cdkDropListData]="rejectedList"
         [cdkDropListConnectedTo]="[approved,awaiting]"
         (cdkDropListDropped)="drop($event)"
    >
      <div class="drag-photo" *ngFor="let photo of rejectedList; let i = index" cdkDrag (click)="showPreview(photo)" (cdkDragStarted)="dragRowStart(photo)" (cdkDragEnded)="dragRowEnd(photo)" [cdkDragData]="photo">
        <div class="drag-photo_wrap">
          <img src="{{photo.url}}" alt="" width="100">
        </div>
      </div>
    </div>
  </div>
</div>
<div class="actions">
  <button mat-button class="update-instrument" [ngClass]="{'disabled': isLoading || awaitingList.length > 0}" (click)="reprocess()" [disabled]="isLoading || awaitingList.length > 0">
    {{ cvFiles.length === 0 ? 'Close' : 'Update dataset and reprocess'}}
  </button>
</div>
