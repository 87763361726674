import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GeneralHelpers } from '../../helpers/general/general.helper';
import { API_BASE_V2, CATALOG } from '../../constants/general.constants';
import { Observable } from 'rxjs';
import { InstrumentResponse } from '../../interfaces/instrument.interface';
import { InstrumentAssembleCreate, InstrumentAssemble } from '../../interfaces/instrument-assemble.interface';

const HUB = "hub";
const TRAY = "tray";
const SECTION = "section";
const INSTRUMENT = 'instrument';
const ASSEMBLY = 'assembly'

@Injectable({
  providedIn: 'root'
})
export class InstrumentAssembleService {

  constructor(private http: HttpClient) { }

  // ────────────────────────────────────────────────────────────────────────────────
  // Create

  create(trayUuid: string, sectionUuid: string, data: InstrumentAssembleCreate): Observable<Object> {
    (data as any) = GeneralHelpers.toMultipartFormData(data)
    return this.http
      .post<InstrumentAssemble>(`${API_BASE_V2}${HUB}/${CATALOG}/${TRAY}/${trayUuid}/${SECTION}/${sectionUuid}/${INSTRUMENT}/${ASSEMBLY}/`, data);
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Read

  get(
    offset: number = 0,
    limit: number = 100,
    ordering: string | null = null,
    search: string | null = null,
    is_pagination: boolean = true,
    trayUuid: string,
    sectionUuid: string,
  ): Observable<InstrumentResponse> {

    const options = {
      params: GeneralHelpers.getParams({ offset, limit, ordering, search, is_pagination }),
    };

    return this.http.get<InstrumentResponse>(
      `${API_BASE_V2}${HUB}/${CATALOG}/${TRAY}/${trayUuid}/${SECTION}/${sectionUuid}/${INSTRUMENT}/${ASSEMBLY}/`, options
    );
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Update

  update(trayUuid: string, sectionUuid: string, instrumentUuid: string, data: InstrumentAssemble): Observable<Object> {
    (data as any) = GeneralHelpers.toMultipartFormData(data);
    return this.http
      .put<InstrumentAssemble>(`${API_BASE_V2}${HUB}/${CATALOG}/${TRAY}/${trayUuid}/${SECTION}/${sectionUuid}/${INSTRUMENT}/${instrumentUuid}/${ASSEMBLY}/`, data);
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Delete

  delete(trayUuid: string, sectionUuid: string, instrumentUuid: string) {
    return this.http.delete(
      `${API_BASE_V2}${HUB}/${CATALOG}/${TRAY}/${trayUuid}/${SECTION}/${sectionUuid}/${INSTRUMENT}/${instrumentUuid}/${ASSEMBLY}/`
    );
  }

}
