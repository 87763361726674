import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, Subject, takeUntil } from 'rxjs';
import { NO_HEADER_AND_FOOTER_ROUTES } from 'src/app/shared/constants/general.constants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  public currentYear: number = new Date().getFullYear();

  private _unsubscribeAll: Subject<any>;

  public isMobile: boolean = false;

  // ────────────────────────────────────────────────────────────────────────────────
  // Show footer
  public showFooter: boolean = false;
  // ────────────────────────────────────────────────────────────────────────────────

  constructor(private router: Router, private breakpointObserver: BreakpointObserver, @Inject(DOCUMENT) private document: Document,) {
    this._unsubscribeAll = new Subject();
    this.router.events
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter((event: any): event is NavigationEnd => event instanceof NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {
        this.showFooter = NO_HEADER_AND_FOOTER_ROUTES.includes(event.url) ? false : true;
      });

    this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
      ])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result) => {
        this.isMobile = result.matches;

        if (this.isMobile) {
          this.document.body.classList.add('is-mobile');
        } else {
          this.document.body.classList.remove('is-mobile');
        }
      });

  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    // ─────────────────────────────────────────────────────────────────
    // *AutoUnsubscribe need ngOnDestroy to be present please do NOT delete
    // ─────────────────────────────────────────────────────────────────
    this._unsubscribeAll.next({});
    this._unsubscribeAll.complete();
  }

}
