import { Inject, Injectable } from '@angular/core';
// @ts-ignore
import * as introJs from 'intro.js/intro.js';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';

declare let introJs: any;
@Injectable()
export class OnboardingService {
  private introJs: any = introJs();
  public tourWorkMode: string = 'trays';
  constructor(private translate: TranslateService,
    @Inject(DOCUMENT) private document: Document) { }

  public startTour(workMode: string) {
    if (this.document.body.classList.contains('is-mobile')) {
      return;
    }

    this.tourWorkMode = workMode;

    this.setViewOptions();
    this.setLabels();
    this.mapSteps();
    this.introJs.start();
    return this.introJs;
  }

  private mapSteps() {
    let currentSteps;
    switch (this.tourWorkMode) {
      case 'trays':
        currentSteps = this.mapTraysSteps();
        break;


      default:
        currentSteps = this.mapTraysSteps();
        break;
    }

    this.introJs.setOptions({ steps: currentSteps });
  }

  private mapTraysSteps() {
    let stepListTrays_1;
    let stepListTrays_2;
    let stepListTrays_3;


    // ─────────────────────────────────────────────────────────────────
    // All group steps

    this.translate
      .get('ONBOARDING.TRAYS.STEP_1')
      .subscribe((res: string) => {
        stepListTrays_1 = res;
      });
    this.translate
      .get('ONBOARDING.TRAYS.STEP_2')
      .subscribe((res: string) => {
        stepListTrays_2 = res;
      });
    this.translate
      .get('ONBOARDING.TRAYS.STEP_3')
      .subscribe((res: string) => {
        stepListTrays_3 = res;
      });



    return [
      {
        element: document.querySelector(
          '#tray-onboarding-step-1'
        ),
        intro: stepListTrays_1,
      },
      {
        element: document.querySelector(
          '#tray-onboarding-step-2'
        ),
        intro: stepListTrays_2,
      },
      {
        element: document.querySelector(
          '#tray-onboarding-step-3'
        ),
        intro: stepListTrays_3,
      },

    ];
  }


  private setViewOptions() {
    this.introJs.setOption('showStepNumbers', false);
    this.introJs.setOption('showBullets', false);
    this.introJs.setOption('showProgress', true);
    this.introJs.setOption('hideNext', false);
  }

  private setNoExit() {
    this.introJs.setOption('exitOnEsc', false);
    this.introJs.setOption('exitOnOverlayClick', false);
  }

  private setLabels() {
    // ─────────────────────────────────────────────────────────────────
    // Labels

    let prevLabel;
    let nextLabel;
    let skipLabel;
    let doneLabel;

    this.translate.get('ONBOARDING.NEXT_LABEL').subscribe((res: string) => {
      nextLabel = res;
    });

    this.translate.get('ONBOARDING.PREV_LABEL').subscribe((res: string) => {
      prevLabel = res;
    });

    this.translate.get('ONBOARDING.SKIP_LABEL').subscribe((res: string) => {
      skipLabel = res;
    });

    this.translate.get('ONBOARDING.DONE_LABEL').subscribe((res: string) => {
      doneLabel = res;
    });

    this.introJs.setOption('nextLabel', nextLabel);
    this.introJs.setOption('prevLabel', prevLabel);
    this.introJs.setOption('skipLabel', skipLabel);
    this.introJs.setOption('doneLabel', doneLabel);
  }

}
