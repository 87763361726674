export class MemoryStorage {

  privateBrowserMode = false;

  private storage: any = {
    Storage: {}
  };

  private saveData() {
    let buffer = {};

    if (/Storage/.test(window.name)) {
      buffer = JSON.parse(window.name);
    }

    window.name = JSON.stringify(Object.assign(buffer, this.storage));
  }

  constructor(typeStorage: "localStorage" | "sessionStorage" = "localStorage") {
    const STORAGE_TEST = "__storage_test__";

    try {
      window[typeStorage].setItem(STORAGE_TEST, STORAGE_TEST);
      window[typeStorage].removeItem(STORAGE_TEST);

      return (<any>window)[typeStorage];
    } catch (e) {
      this.saveData();

      this.privateBrowserMode = true;

      return this;
    }
  }

  setItem(name: string, data: any) {
    this.storage.Storage[name] = data;

    this.saveData();
  }

  getItem(name: string) {
    if (/Storage/.test(window.name)) {
      this.storage = JSON.parse(window.name);
    } else {
      this.storage = {
        Storage: {}
      };
    }

    return this.storage.Storage[name];
  }

  removeItem(name: string) {
    delete this.storage.Storage[name];

    this.saveData();
  }

  clear() {
    this.storage = {
      Storage: {}
    };

    this.saveData();
  }
}
