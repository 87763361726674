import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HeaderComponent, InviteDialog} from './header.component';
// Material UI
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
// Flex layout
import { FlexLayoutModule } from '@angular/flex-layout';
// Router
import { RouterModule } from '@angular/router';
// ────────────────────────────────────────────────────────────────────────────────
// Directives
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
// Permissions
import { NgxPermissionsModule } from 'ngx-permissions';
// ─────────────────────────────────────────────────────────────────────────────
// Translations
import { TranslateModule } from '@ngx-translate/core';
import {ClipboardModule} from "@angular/cdk/clipboard";

@NgModule({
    imports: [
        CommonModule,
        // Material
        MatToolbarModule,
        MatButtonModule,
        MatMenuModule,
        MatIconModule,
        MatSelectModule,
        MatDividerModule,
        MatInputModule,
        // Flex layout
        FlexLayoutModule,
        // Directives
        DirectivesModule,
        // Permissions
        NgxPermissionsModule,
        RouterModule,
        // Translations
        TranslateModule.forChild(),
        //copy
        ClipboardModule,
    ],
    declarations: [HeaderComponent, InviteDialog],
    exports: [HeaderComponent]
})
export class HeaderModule { }
