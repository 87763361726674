import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {InstrumentDialog, InstrumentResponse} from '../../interfaces/instrument.interface';
import {MessageService} from '../../services/message/message.service';
import {environment} from "../../../../environments/environment";
import {PermissionsService} from "../../services/permissions/permission.service";
import {InstrumentsService} from "../../services/instruments/instruments.service";

@Component({
  selector: 'app-intrument-search-result-dialog',
  templateUrl: './intrument-search-result-dialog.component.html',
  styleUrls: ['./intrument-search-result-dialog.component.scss']
})
export class IntrumentSearchResultDialogComponent implements OnInit {
  loading = false;
  hasResults = true;

  imageLoaded = false;
  imagePreview = 'assets/img/no-result.svg';
  imageQuery = [
    'cute puppy',
    'cute cat',
    'cute animals'
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: InstrumentDialog | any,
    private messageService: MessageService,
    private permissionsService: PermissionsService,
    private instrumentsService: InstrumentsService
  ) {
    this.hasResults = data && data.data && Array.isArray(data.data.results) && data.data.results.length > 0;
    data.data.instrumentCount = 1;
    data.data.selectedInstrument = data && data.data && Array.isArray(data.data.results) && data.data.results.length === 1 ? data.data.results[0] : null;
    if(data.data.selectedInstrument) {
      this.setSelected(data.data.selectedInstrument.uuid);
    }

    if(!this.hasResults) {
      this.instrumentsService.getRandomImage(this.imageQuery[Math.floor(Math.random() * this.imageQuery.length)], 'animals').subscribe((res: any) => {
        if(res.urls && res.urls.small) {
          this.imagePreview = res.urls.small;
        }
        this.imageLoaded = true;
      }, (err: any) => {
        this.imageLoaded = true;
      });
    }
  }

  ngOnInit() {

  }

  openinstrumentContributionDialog() {
    this.messageService.sendMessage('openInstrumentContributionDialog', {message: 'doInstrumentSearch'});
  }

  setSelected(uuid: string | any) {
    for (let index = 0; index < this.data.data.results.length; index++) {
      this.data.data.results[index].isSelected = false;
      if (uuid === this.data.data.results[index].uuid) {
        this.data.data.selectedInstrument = this.data.data.results[index];
        this.data.data.results[index].isSelected = true;
      }
    }
  }

  sendInsRequest() {
    this.sendInstRequestEmail({text: this.data.q });
  }

  sendInstRequestEmail(data: any) {
    const br = '%0D%0A';
    const subject = 'HUB - New instrument request';
    let body = 'Please add this to HUB:' + br + br;
    body = body + 'Instrument: ' + data.text + br;
    body = body + 'Reason: ' + br + br;
    body = body + 'Thank you,' + br + br + (this.permissionsService.userProfile.full_name || '') + br + this.permissionsService.userProfile.email;
    window.open('mailto:' + environment.supportEmail + '?subject=' + subject + '&body=' + body, 'Mail');
  }
}
