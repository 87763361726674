<div class="title">Image preview</div>
<div class="close-btn">
  <button mat-icon-button aria-label="Close dialog" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="image-holder p-0 m-0" mat-dialog-content>
  <img
    *ngIf="data"
    [src]="data"
    alt="fullscreen"
    class="position-absolute top-50 start-50 translate-middle mh-100 mw-100 mx-auto"
  >
</div>
