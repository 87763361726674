<mat-card appearance="outlined" class="adnvanced-filters mat-elevation-z0 w100">
  <h2>Advanced views</h2>
  <button mat-button class="close" [mat-dialog-close]="true">
    <mat-icon>close</mat-icon>
  </button>
  <mat-card-content fxLayout="column" fxLayoutAlign="start start">
    <div fxLayout="row" fxLayoutAlign="start start" class="w100" fxLayoutGap="2%">
      <div fxFlex="25">
        <p class="title">Last update by</p>
        <p class="operator">and</p>
      </div>
      <div fxFlex="75">
        <mat-form-field
          class="w100 custom-label"
          floatLabel="always"
          [ngClass]="{ loading: userLoadById }"
        >
          <mat-label>Select user</mat-label>
          <input
            type="text"
            aria-label="Last update by"
            matInput
            [matAutocomplete]="userAutocomplete"
            #userAutocompleteInput
          />
          <mat-icon marPrefix class="autocomplete-input-icon"
            >arrow_drop_down</mat-icon
          >
          <mat-autocomplete
            #userAutocomplete="matAutocomplete"
            [displayWith]="displayUser"
          >
            <mat-option disabled class="loading pt-3 pb-3" *ngIf="userLoading">
            </mat-option>
            <mat-option disabled *ngIf="noUsersFound">
              No user found
            </mat-option>
            <mat-option
              *ngFor="let user of usersAutocompleteData | async"
              [value]="user"
            >
              {{ user.id ? user.id : "No id" }} |
              {{ user.email ? user.email : "No email" }} |
              {{ user.full_name ? user.full_name : "No fullname" }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start start" class="w100" fxLayoutGap="2%">
      <div fxFlex="25">
        <p class="title">Last update date</p>
        <p class="operator">and</p>
      </div>
      <div fxFlex="75">
        <mat-form-field class="w100 custom-label" floatLabel="always">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate readonly />
          <input matEndDate readonly />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start start" class="w100" fxLayoutGap="2%">
      <div fxFlex="25">
        <p class="title">Number of catalog images</p>
        <p class="operator">and</p>
      </div>
      <div fxFlex="75">
        <mat-form-field class="w100 custom-label" floatLabel="always">
        <mat-label>Catalog images</mat-label>
        <mat-select>
          <mat-option
            *ngFor="let image of catalogImagesList"
            [value]="image.value"
          >
            {{ image.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start start" class="w100" fxLayoutGap="2%">
      <div fxFlex="25">
        <p class="title">Vendor</p>
        <p class="operator">and</p>
      </div>
      <div fxFlex="75">
         <mat-form-field
        class="w100 custom-label"
        floatLabel="always"
        [ngClass]="{ loading: vendorLoadById }"
      >
        <mat-label>Vendor</mat-label>
        <input
          type="text"
          aria-label="Vendor"
          matInput
          [matAutocomplete]="vendorAutocomplete"
          #vendorAutocompleteInput
        />
        <mat-autocomplete
          #vendorAutocomplete="matAutocomplete"
          [displayWith]="displayVendor"
        >
          <mat-option disabled class="loading pt-3 pb-3" *ngIf="vendorLoading">
          </mat-option>
          <mat-option disabled *ngIf="noVendorsFound">
            No vendor found
          </mat-option>
          <mat-option
            *ngFor="let vendor of vendorsAutocompleteData | async"
            [value]="vendor"
          >
            {{ vendor.id ? vendor.id : "No id" }} |
            {{ vendor.name ? vendor.name : "No name" }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start start" class="w100" fxLayoutGap="2%">
      <div fxFlex="25">
        <p class="title pt-0">Status</p>
        <p class="operator">and</p>
      </div>
      <div fxFlex="75">
        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px" class="checkbox-wrapper">
        <ng-container *ngFor="let value of statusValues">
          <mat-checkbox color="primary">{{value}}</mat-checkbox>
        </ng-container>
      </div>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start start" class="w100" fxLayoutGap="2%">
      <div fxFlex="25">
        <p class="title pt-0">Visibility</p>
        <p class="operator">and</p>
      </div>
      <div fxFlex="75">
        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px" class="checkbox-wrapper">
        <ng-container *ngFor="let value of visibilityValues">
          <mat-checkbox color="primary">{{value}}</mat-checkbox>
        </ng-container>
      </div>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start start" class="w100" fxLayoutGap="2%">
      <div fxFlex="25">
        <p class="title pt-0">Scans</p>
        <p class="operator">and</p>
      </div>
      <div fxFlex="75">
        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px" class="checkbox-wrapper">
        <ng-container *ngFor="let value of scansValues">
          <mat-checkbox color="primary">{{value}}</mat-checkbox>
        </ng-container>
      </div>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start start" class="w100" fxLayoutGap="2%">
      <div fxFlex="25">
        <p class="title pt-0">Notes</p>
      </div>
      <div fxFlex="75">
        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px" class="checkbox-wrapper">
        <ng-container *ngFor="let value of notesValues">
          <mat-checkbox color="primary">{{value}}</mat-checkbox>
        </ng-container>
      </div>
      </div>
    </div>
  </mat-card-content>
  <mat-card-actions fxLayout="row" fxLayoutAlign="end center">
    <button
      mat-button
      class="tray-button blue"
      aria-label="Create organization"
      [class.loading]="loading"
      [disabled]="loading"
      [mat-dialog-close]="data"
    >
      Refresh
    </button>
  </mat-card-actions>
</mat-card>
