import { NgModule } from '@angular/core';
import { AvatarFallback } from './avatar-fallback.directive';


@NgModule({
  declarations: [
    AvatarFallback,
  ],
  exports: [
    AvatarFallback,
  ]
})
export class DirectivesModule { }
