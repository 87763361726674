import { MatSelectChange } from '@angular/material/select';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {AbstractControl, AsyncValidatorFn, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import {EMPTY, map, Observable, tap, startWith, debounceTime, distinctUntilChanged, switchMap, first, of} from 'rxjs';
import { AutoUnsubscribe } from '../../decorators/auto-unsubscribe.decorator';
import { acceptedFileImages, CATALOG_INSTRUMENT_STATUSES, CATALOG_INSTRUMENT_STATUSES_VISIBILITY, GeneralHelpers, MAX_STRING_LENGTH } from '../../helpers/general/general.helper';
import { FileObject } from '../../interfaces/file.interface';
import { InstrumentCatalog, Vendor } from '../../interfaces/instrument.interface';
import { InstrumentsService } from '../../services/instruments/instruments.service';
import { MessageService } from '../../services/message/message.service';
import { VendorService } from '../../services/vendor/vendor.service';
// ─────────────────────────────────────────────────────────────────────────────
// Capture
import { InstrumentCapture } from '../../interfaces/instrument-capture.interface';
import { InstrumentNoteService } from '../../services/instrument-note/instrument-note.service';
import { OrganizationsService } from '../../services/organizations/organizations.service';

import SwiperCore, { Pagination, Navigation } from 'swiper';
import { DomSanitizer } from "@angular/platform-browser";

SwiperCore.use([Pagination, Navigation]);

@Component({
  selector: 'app-instrument-form',
  templateUrl: './instrument-form.component.html',
  styleUrls: ['./instrument-form.component.scss']
})
@AutoUnsubscribe()
export class InstrumentFormComponent implements OnInit, OnDestroy {

  @Input() instrumentData: InstrumentCatalog | any = {
    uuid: '',
    images: []
  };

  @Input() workMode!: 'update' | 'create';

  @Input() afterSubmitMessage = '';

  public statuses = CATALOG_INSTRUMENT_STATUSES;

  public statusesVisibility = CATALOG_INSTRUMENT_STATUSES_VISIBILITY;

  public loading: boolean = false;

  public title: string = 'Create instrument';

  public acceptedFileImages = acceptedFileImages;

  public instrumentFile!: File | any;

  public catalogFile!: FileList | any;

  public instrumentForm!: UntypedFormGroup;

  public stopClick: boolean = false;

  @ViewChild('instrumentDataFile', { static: false }) instrumentDataFile!: ElementRef;
  @ViewChild('catalogDataFile', { static: false }) catalogDataFile!: ElementRef;

  generalHelpers = GeneralHelpers;

  vendorsAutocompleteData!: Observable<Vendor[]> | any;

  canUpdate: boolean = false;

  vendorLoading = false;
  noVendorsFound = false;
  vendorLoadById = false;

  instrumentFormFlags: any = {
    sku: false,
    name: false,
    //description: false,
    inst_type: false,
    ster_method: false,
    tray: false,
    tray_section: false,
    tags: false,
    is_public: false,
  };

  removedFiles: FileObject[] = []

  organizationLoading = false;
  noOrganizationsFound = false;
  organizationLoadById = false;

  private hiddenFieldsWhenCreate = ['inst_type', 'ster_method', 'ster_method', 'tray', 'tray_section', 'tags', 'categorical_value', 'is_public'];

  @ViewChild('instrumentTabs', { static: true }) instrumentTabs: any;

  // ─────────────────────────────────────────────────────────────────────────────
  // Scans
  public scansLoading: boolean = false;
  public instrumentCaptureData!: InstrumentCapture | any;

  public catalogImageStatuses = [
    {
      value: true,
      viewValue: 'Approved'
    }, {
      value: false,
      viewValue: 'Waiting approval'
    }
  ];
  public fileStatusValue = this.catalogImageStatuses[0];
  public isDeleteImage = false;

  constructor(
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly instrumentsService: InstrumentsService,
    private readonly toastr: ToastrService,
    private readonly messageService: MessageService,
    private readonly vendorService: VendorService,
    public instrumentNoteService: InstrumentNoteService,
    // private customDialog: DialogService,
    private readonly organizationsService: OrganizationsService,
    private readonly sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    if (this.workMode === 'update') {
      this.title = 'Edit instrument';
      if (this.instrumentData?.vendor?.id) {
        this.getVendorById(this.instrumentData?.vendor?.id);
      }
      // this.getCatalogImages();
    } else if (this.workMode === 'create') {
      GeneralHelpers.removeValidationAndHideField(this.hiddenFieldsWhenCreate, 'instrumentForm', 'instrumentFormFlags', this);
    }
    this.prepareData();
    this.initForm();
    this.prepareVendorsAutocomplete();
    this.setUpdateStatus();
  }

  ngOnDestroy(): void {
    // ─────────────────────────────────────────────────────────────────
    // *AutoUnsubscribe need ngOnDestroy to be present please do NOT delete
    // ─────────────────────────────────────────────────────────────────
  }

  private setUpdateStatus() {
    const organization = this.organizationsService.getOrganizationFromProfile();
    const organizationMatch = this.instrumentData.organization_owner === organization.id;
    this.canUpdate = organizationMatch;
  }

  public prepareData() {
    this.instrumentData.status = parseInt(this.instrumentData?.status as string, 10)
  }

  public processInstrument(formValue: any, isValid: boolean, $event: Event) {
    $event.preventDefault();

    if (!isValid) {
      return;
    }

    if (this.workMode === 'update' && this.canUpdate === false) {
      this.toastr.warning("You cannot update instruments");
      return;
    }

    if(this.isDeleteImage) {
      formValue.isDeleteImage = true;
    }
    this.loading = true;
    if(formValue.vendor && formValue.vendor.id) {
      this.updateInstrument(formValue);
    } else {
      this.vendorService.create({name: formValue.vendor}).subscribe({
        next: (res: any) => {
          formValue.vendor = {id: res.id, name: formValue.vendor}
          this.updateInstrument(formValue);
        },
        error: () => {
          this.loading = false;
        },
        complete: () => { },
      })
    }
  }

  updateInstrument(formValue: any) {
    let instrumentData: InstrumentCatalog = {
      //description: formValue.description,
      name: formValue.name,
      sku: formValue.sku,
      vendor: formValue.vendor.id,
      status: formValue.status || 2,
      is_public: formValue.is_public || false,
      ster_method: 'Steam',
      is_photo_delete: formValue.isDeleteImage ? true : null
    };

    instrumentData = GeneralHelpers.filterNullObject(instrumentData);

    if (this.instrumentFile) {
      instrumentData.photo = this.instrumentFile;
    }

    if (this.catalogFile) {
      instrumentData.files = this.catalogFile;
    }

    if (this.workMode === 'create') {
      this.instrumentsService.create(instrumentData).subscribe({
        next: (res: any) => {
          this.loading = false;
          this.messageService.sendMessage('instrument_created');
          if(this.afterSubmitMessage) {
            this.messageService.sendMessage(this.afterSubmitMessage, res);
          }
          this.toastr.success("Instrument created");
        },
        error: () => {
          this.loading = false;
        },
        complete: () => { },
      })
    }

    if (this.workMode === 'update') {
      this.instrumentsService.update(this.instrumentData.uuid, instrumentData).subscribe({
        next: (res: any) => {
          this.loading = false;
          this.messageService.sendMessage('instrument_updated');
          if(this.afterSubmitMessage) {
            this.messageService.sendMessage(this.afterSubmitMessage, res);
          }
          this.toastr.success("Instrument updated");
        },
        error: () => {
          this.loading = false;
        },
        complete: () => { },
      })
    }
  }

  public handleFileInput(event: Event, workMode: 'photo' | 'catalog') {
    const target = event.target as HTMLInputElement | any;
    const file: File = target.files[0];
    if (workMode === 'photo') {
      this.instrumentFile = file;
      this.isDeleteImage = false;
    }
    if (workMode === 'catalog') {
      this.catalogFile = file;
    }
  }

  public getFileUploadPreviewImage(file: File) {
    return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
  }

  public clearFile(isDelete: boolean = false) {
    //this.instrumentDataFile.nativeElement.value = '';
    this.instrumentFile = null;
    if (isDelete) {
      this.instrumentData.image1 = null;
      this.instrumentData.image2 = null;
      this.isDeleteImage = true;
    }
  }

  public clearCatalogFile() {
    this.catalogDataFile.nativeElement.value = '';
    this.catalogFile = null;
  }

  validateSKUNotTaken(initValue = ''): AsyncValidatorFn {
    return control => {
      if(control.value == initValue) {
        return of(null);
      }
      return control.valueChanges
        .pipe(
          debounceTime(500),
          distinctUntilChanged(),
          switchMap((value: string) => this.validateSKUNotTakenCheck(value)),
          map((exists: boolean) => (exists ? {'skuExists': true} : null)),
          first()); // important to make observable finite
    }
  }

  validateSKUNotTakenCheck(value: string) {
      return this.instrumentsService.getBySKU(value).pipe(
        map((res: any) => {
          if(res.length > 0) {
            return res.filter((r: any) => r.sku.toLowerCase() === value.toLowerCase()).length > 0;
          } else {
            return false;
          }
        })
      );
  }

  private initForm() {
    this.instrumentForm = this._formBuilder.group({
      vendor: [
        this.instrumentData.vendor ? this.instrumentData.vendor : '',
        [
          Validators.required,
        ],
      ],
      sku: [
        this.instrumentData.sku ? this.instrumentData.sku : '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(MAX_STRING_LENGTH),
        ],
        [this.validateSKUNotTaken(this.instrumentData.sku || '')]
      ],
      name: [
        this.instrumentData.name ? this.instrumentData.name : '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(MAX_STRING_LENGTH),
        ],
      ],
      status: [this.instrumentData.status],
      // description: [
      //   this.instrumentData.description ? this.instrumentData.description : '',
      //   [
      //     Validators.required,
      //     Validators.minLength(3),
      //     Validators.maxLength(MAX_STRING_LENGTH),
      //   ],
      // ],
      is_public: [this.instrumentData.is_organization_public],

    });
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Autocomplete vendors

  private prepareVendorsAutocomplete() {
    this.vendorsAutocompleteData = this.instrumentForm.get('vendor')?.valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap((val: string) => {
        return this.getVendors(val)
      })
    );
  }

  private getVendorById(id: number | string) {
    this.vendorLoadById = true;
    this.vendorService.getById(id).subscribe({
      next: (vendor: Vendor) => {
        this.vendorLoadById = false;
        this.instrumentForm.get('vendor')?.setValue({
          id: vendor.id,
          name: vendor.name,
        })
      },
      error: (err: any) => {
        this.vendorLoadById = false;
      }
    })
  }

  private getVendors(val: string): Observable<any[]> {
    if (typeof val !== 'string' || val === '') {
      return EMPTY;
    }
    this.noVendorsFound = false;
    return this.vendorService.get(0, 25, '', val).pipe(
      map((response: Vendor[]) =>
        response.filter((option) => {
          return (
            option?.name?.toLowerCase().indexOf(val.toLowerCase()) === 0
          );
        })
      ),
      tap((vendors: Vendor[]) => {
        if (vendors.length === 0) {
          this.noVendorsFound = true;
        }
      })
    );
  }

  public displayVendor(vendor: Vendor) {
    if (typeof vendor === 'object' && vendor !== null) {
      return `${vendor.name ? vendor.name : 'No name'}`;
    } else {
      return ''
    }
  }

  // ─────────────────────────────────────────────────────────────────────────────
  // Catalog image
  // public showSingleImage(url: string) {
  //   const data = {
  //     files: [{
  //       url: url
  //     }],
  //     index: 0
  //   }
  //   this.customDialog.imageDialog(data).subscribe();
  // }

  // ─────────────────────────────────────────────────────────────────────
  // Image slider

/*  getCatalogImages() {
    const params: InstrumentGetParams = {
      limit: 25,
      offset: 0,
      is_pagination: true,
    }
    this.instrumentsService.files(this.instrumentData?.uuid, params).subscribe({
      next: (files: FileObjectResponse) => {
        if (Array.isArray(files.results) && files.results.length > 0) {
          this.imageSlider.catalogImages = files.results;
          this.setImage();
        }
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      },
    })
  }*/

  // updateFileStatus(row: any, status: boolean) {
  //   this.loading = true;
  //   this.instrumentsService.updateFile(this.instrumentData?.uuid, row.uuid, { is_approved: status }).subscribe({
  //     next: (data: any) => {
  //       row.is_approved = status;
  //       this.loading = false;
  //     },
  //     error: () => { this.loading = false; },
  //   })
  // }

  deleteFile(row: any) {
    this.loading = true;
    this.instrumentsService.deleteFile(this.instrumentData?.uuid, row.uuid).subscribe({
      next: (data: any) => {
        this.instrumentData.images_num--;
        this.instrumentData.images = this.instrumentData.images.filter((r: any) => r.uuid !== row.uuid);
        this.loading = false;
      },
      error: () => { this.loading = false; },
    })
  }

  private setFileStatus() {
    // this.fileStatusValue = this.imageSlider.catalogImages[this.imageSlider.index].is_approved ? this.catalogImageStatuses[0] : this.catalogImageStatuses[1];
  }

  slideWillChange() {
    this.stopClick = true;
  }

  slideDidChange() {
    this.stopClick = false;
  }

  public cancel() {
    this.messageService.sendMessage('instrument_cancel')
  }
}
