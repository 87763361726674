import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { environment } from './../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderModule } from './layout/header/header.module';
import { FooterModule } from './layout/footer/footer.module';
import { AuthModule } from '@auth0/auth0-angular';
import {InfiniteScrollModule} from "ngx-infinite-scroll";


// ────────────────────────────────────────────────────────────────────────────────
// Toastr
import { ToastrModule } from 'ngx-toastr';
// ────────────────────────────────────────────────────────────────────────────────
// Shared module
import { SharedModule } from './shared/shared.module';
// ────────────────────────────────────────────────────────────────────────────────
// Permissions
import { NgxPermissionsModule } from 'ngx-permissions';
import { PermissionsService } from './shared/services/permissions/permission.service';
// ─────────────────────────────────────────────────────────────────────────────
// Flex layout
import { FlexLayoutModule } from '@angular/flex-layout';
// ─────────────────────────────────────────────────────────────────────────────
// Translation support
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import {CookieService} from "ngx-cookie-service";


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

// Auth data
const { apiUrlV1, apiUrlV2 } = environment;
const { domain, clientId, audience, redirectUri, scope, cacheLocation, useRefreshTokens, useRefreshTokensFallback } = environment.auth;
const httpInterceptor = {
  allowedList: [
    { uri: `${apiUrlV1}*` },
    { uri: `${apiUrlV2}*` },
  ],
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    // Layer
    HeaderModule,
    FooterModule,
    // Auth
    AuthModule.forRoot({
      domain,
      clientId,
      authorizationParams: {
        redirect_uri: redirectUri,
        audience: audience,
        scope: scope,
      },
      cacheLocation,
      useRefreshTokens,
      useRefreshTokensFallback,
      httpInterceptor,
    }),
    ToastrModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    SharedModule,
    FlexLayoutModule,
    InfiniteScrollModule,
    // Translations
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    PermissionsService,
    CookieService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
  }
}
