import {Component, EventEmitter, Inject, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {OrganizationsService} from "../../services/organizations/organizations.service";
import {PermissionsService} from "../../services/permissions/permission.service";

@Component({
  selector: 'app-instrument-edit',
  templateUrl: './instrument-edit.component.html',
  styleUrls: ['./instrument-edit.component.scss']
})
export class InstrumentEditComponent {

  @Output() updateInstruments = new EventEmitter<any>();

  public isLayerJotAdmin = false;

  constructor(
    public dialogRef: MatDialogRef<InstrumentEditComponent>,
    public organizationsService: OrganizationsService,
    public permissionsService: PermissionsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.permissionsService.orgPermission$.subscribe(res => {
      if(res) {
        this.isLayerJotAdmin = res?.isLayerJotAdmin;
      }
    })
  }


  onCancelClick(): void {
    this.dialogRef.close();
    this.updateInstruments.emit();
  }

}
