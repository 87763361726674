import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GeneralHelpers } from '../../helpers/general/general.helper';
import { API_BASE_V2, CATALOG } from '../../constants/general.constants';
import {map, Observable, zip} from 'rxjs';
import { InstrumentCapture, InstrumentCaptureResponse } from '../../interfaces/instrument-capture.interface';
import {Sort} from "@angular/material/sort";

const HUB = 'hub';
const CAPTURE = 'capture';
const INSTRUMENT = 'instrument';

@Injectable({
  providedIn: 'root'
})
export class InstrumentCaptureService {

  constructor(private http: HttpClient) { }

  // ────────────────────────────────────────────────────────────────────────────────
  // Create

  create(data: { data: InstrumentCapture }): Observable<Object> {
    (data as any) = GeneralHelpers.toMultipartFormData(data.data)
    return this.http
      .post<InstrumentCapture>(`${API_BASE_V2}${HUB}/${CATALOG}/${CAPTURE}/${INSTRUMENT}/`, data);
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Read

  get(params: any): Observable<InstrumentCapture[]> {
    let { offset, limit, ordering, search, is_pagination, is_lazy_loading_pagination, status } = params;
    const options = {
      params: GeneralHelpers.getParams({ offset, limit, ordering, search, is_pagination, is_lazy_loading_pagination, status }),
    };

    return this.http.get<InstrumentCapture[]>(
      `${API_BASE_V2}${HUB}/${CATALOG}/${CAPTURE}/${INSTRUMENT}/`, options
    );
  }

  // ─────────────────────────────────────────────────────────────────────────────
  // Get by id
  getById(
    id: string
  ): Observable<InstrumentCapture> {
    return this.http.get<InstrumentCapture>(
      `${API_BASE_V2}${HUB}/${CATALOG}/${CAPTURE}/${INSTRUMENT}/${id}/`
    );
  }

  getFiles(
   id: string,
   organization_uuid: string,
   limit: number = 200
  ): Observable<InstrumentCapture[]> {
    const options = {
      params: GeneralHelpers.getParams({ limit, organization_uuid }),
      headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'LJ-User-Organization': organization_uuid,
      })
    };
    return this.http.get<InstrumentCapture[]>(
      `${API_BASE_V2}${HUB}/${CATALOG}/${CAPTURE}/${INSTRUMENT}/${id}/file/`, options
    );
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Update

  update(id: number, data: { data: InstrumentCapture }): Observable<Object> {
    (data as any) = GeneralHelpers.toMultipartFormData(data.data)
    return this.http
      .put<InstrumentCapture>(`${API_BASE_V2}${HUB}/${CATALOG}/${CAPTURE}/${INSTRUMENT}/${id}/`, data);
  }

  updateFile(instID: string, fileId: number, orgUUID: string, data: any): Observable<Object> {
    const options = {
      headers: new HttpHeaders({
        'LJ-User-Organization': orgUUID,
      })
    };
    data = GeneralHelpers.toMultipartFormData(data);
    return this.http
      .put(`${API_BASE_V2}${HUB}/${CATALOG}/${CAPTURE}/${INSTRUMENT}/${instID}/file/${fileId}/`, data, options);
  }

  reprocessInst(list: any) {
    const observableList: any[] = [];
    list.forEach((instID: string) => {
      const data = GeneralHelpers.toMultipartFormData([]);
      let path = `${API_BASE_V2}${HUB}/${CATALOG}/${CAPTURE}/${INSTRUMENT}/${instID}/`;
      observableList.push(
        this.http.patch(path, data)
      );
    });
    return zip(...observableList).pipe(
      map(
        (r: any) => {
          return r;
        }
      )
    );
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Actions

  doVisionAction(id: number, body: { file_ids: number[], action: number }) {
    (body as any) = GeneralHelpers.toMultipartFormData(body);
    return this.http.put(
      `${API_BASE_V2}${HUB}/${CATALOG}/${CAPTURE}/${INSTRUMENT}/${id}/`, body
    );
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Delete
  delete(id: string, body: { is_delete_permanently: boolean }) {
    (body as any) = GeneralHelpers.toMultipartFormData(body);
    return this.http.delete(
      `${API_BASE_V2}${HUB}/${CATALOG}/${CAPTURE}/${INSTRUMENT}/${id}/`, { body }
    );
  }

  getSortDirection(sort: Sort) {
    if(sort?.direction === 'desc') {
      return '-' + sort?.active;
    }
    return sort?.active;
  }

  mapCaptureInstruments(results: any) {
    results.forEach((result: any, i: number) => {
      results[i].mainImage = results[i]?.image1 ?? null;
      results[i].images = [];
      if (result.files.length > 0) {
        result.files.forEach((file: any) => {
          if (file.is_image && file.is_approved) {
            results[i].images.push(file.url);
          }
        });
      }
      if(results[i].images.length > 0) {
        results[i].mainImage = results[i].images[0];
      }
    });
    return results;
  }

}
