import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GeneralHelpers } from '../../helpers/general/general.helper';
import { Observable, Subject } from 'rxjs';
import { InstrumentNote, InstrumentNoteResponse } from '../../interfaces/instrument-note.interface';
import {API_BASE_V1, API_BASE_V2, CATALOG} from '../../constants/general.constants';
import { Instrument } from '../../interfaces/instrument.interface';
import {TrayNoteDTO} from "../../interfaces/tray-note.interface";

const INSTRUMENT = 'instrument';
const NOTE = 'note';
const EDITOR = 'editor';
const JOT = 'jot';
const VIDEO = 'video';

@Injectable({
  providedIn: 'root'
})
export class InstrumentNoteService {

  public noteLoading: boolean = false;
  public noteList: InstrumentNote[] = [];
  public mappedNoteList: InstrumentNote[] = [];

  awaitingApprovalNoteList: InstrumentNote[] = [];

  approvedNoteList: InstrumentNote[] = [];

  public selectedNote!: InstrumentNote;
  public selectedInstrument!: Instrument;

  public $instrumentData = new Subject<{
    note: InstrumentNote;
    index: number;
  }>();

  public $mappedNoteList = new Subject<InstrumentNote[]>();
  public $noteLoading = new Subject<boolean>();

  constructor(private readonly http: HttpClient) { }

  // ────────────────────────────────────────────────────────────────────────────────
  // Create

  create(data: TrayNoteDTO): Observable<Object> {
    let { instrumentUuid, body } = data;
    (body as any) = GeneralHelpers.toMultipartFormData(body);
    return this.http
      .post<InstrumentNote>(`${API_BASE_V2}${CATALOG}/${INSTRUMENT}/${instrumentUuid}/${NOTE}/`, body);
  }

  getNoteData(instrumentUuid: string | any) {
    this.noteLoading = true;
    this.$noteLoading.next(true);
    this.resetNoteLists();
    this.get(0, 25, true, instrumentUuid).subscribe({
      next: (response: InstrumentNoteResponse) => {
        if (Array.isArray(response.results) && response.results.length > 0) {
          this.noteList = GeneralHelpers.sortArrayOfObjects(response.results, 'asc', 'order');
          this.mappedNoteList = GeneralHelpers.mapNotesData(response.results);
          this.$mappedNoteList.next(this.mappedNoteList)
          this.sortNotesByStatus(response.results);
        }
        this.noteLoading = false;
        this.$noteLoading.next(false);
      },
      error: () => {
        this.noteLoading = false;
        this.$noteLoading.next(false);
        this.resetNoteLists();
      },
      complete: () => {
        //this.noteLoading = false;
        //this.$mappedNoteList.next([])
      }
    })
  }

  public sortNotesByStatus(notesList: InstrumentNote[] = this.noteList): void {
    const notes = GeneralHelpers.mapNotesData(notesList);
    this.awaitingApprovalNoteList = [];
    this.approvedNoteList = [];
    if (Array.isArray(notes) && notes.length > 0) {
      for (let index = 0; index < notes.length; index++) {
        const element = notes[index];
        const status = typeof element.src.status === 'string' ? parseInt(element.src.status, 10) : typeof element.src.status === 'number' ? element.src.status : 1;
        switch (status) {
          case 1:
            this.awaitingApprovalNoteList.push(element);
            break;
          case 2:
            this.approvedNoteList.push(element);
            break;
          case 3:
            this.awaitingApprovalNoteList.push(element);
            break;
          default:
            this.awaitingApprovalNoteList.push(element);
            break;
        }

      }
    }
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Read

  get(
    offset: number = 0,
    limit: number = 25,
    is_pagination: boolean = true,
    instrumentUuid: string,
  ): Observable<InstrumentNoteResponse> {
    const options = {
      params: GeneralHelpers.getParams({ offset, limit, is_pagination }),
    };
    return this.http.get<InstrumentNoteResponse>(
      `${API_BASE_V2}${CATALOG}/${INSTRUMENT}/${instrumentUuid}/${NOTE}/`, options
    );
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Update

  editNote(uuid: string) {
    (this.selectedNote as any) = {};
    const index = this.noteList.findIndex((item) => item.uuid === uuid);
    if (index > -1) {
      this.$instrumentData.next({
        note: this.noteList[index],
        index: index
      })
    }
  }

  update(instrumentUuid: string | any, noteUuid: string, data: Partial<InstrumentNote>): Observable<Object> {
    (data as any) = GeneralHelpers.toMultipartFormData(data);
    return this.http
      .put<InstrumentNote>(`${API_BASE_V2}${CATALOG}/${INSTRUMENT}/${instrumentUuid}/${NOTE}/${noteUuid}/`, data);
  }

  updateOrder(instrumentUuid: string | any, noteUuid: string | any, toTop: boolean) {
    if (noteUuid === null) {
      return;
    }
    this.noteLoading = true;
    const index = this.approvedNoteList.findIndex((item) => item.uuid === noteUuid);
    this.approvedNoteList[index].src.order = toTop ? this.approvedNoteList[index].src.order - 1 : this.approvedNoteList[index].src.order + 1;
    if (this.approvedNoteList[index].src.order < 0) this.approvedNoteList[index].src.order = 0;
    this.update(instrumentUuid, noteUuid, {
      order: this.approvedNoteList[index].src.order,
      status: this.approvedNoteList[index].status,
    }).subscribe({
      next: (data: InstrumentNote | any) => {
        //this.approvedNoteList[index] = data;
        this.noteLoading = false;
      },
      error: () => {
        this.noteLoading = false;
      }
    })
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Delete

  deleteNote(instrumentUuid: string, noteUuid: string) {
    this.noteLoading = true;
    this.delete(instrumentUuid, noteUuid).subscribe({
      next: () => {
        const index = this.noteList.findIndex((item) => item.uuid === noteUuid);
        const approvedIndex = this.approvedNoteList.findIndex((item) => item.uuid === noteUuid);
        this.noteList.splice(index, 1);
        this.approvedNoteList.splice(approvedIndex, 1);
        this.noteLoading = false;
      },
      error: () => {
        this.noteLoading = false;
      }
    })
  }

  delete(instrumentUuid: string, noteUuid: string) {
    return this.http.delete(
      `${API_BASE_V2}${CATALOG}/${INSTRUMENT}/${instrumentUuid}/${NOTE}/${noteUuid}/`
    );
  }

  generateJWPlayerToken() {
    return this.http.post(`${API_BASE_V1}${EDITOR}/${JOT}/${VIDEO}/`, '');
  }

  uploadVideoToJWStorage(data: any, file: File) {
    const url = 'https://' + data.link.address + data.link.path +
      '?api_format=json&key=' + data.link.query.key +
      '&token=' + data.link.query.token;
    const creds: FormData = new FormData();
    creds.append('file', file);
    return this.http.post(url, creds);
  }

  // ─────────────────────────────────────────────────────────────────────
  // Support methods
  private resetNoteLists() {
    this.noteList = [];
    this.mappedNoteList = [];
    this.awaitingApprovalNoteList = [];
    this.approvedNoteList = [];
    this.$mappedNoteList.next(this.mappedNoteList)
  }

  getInstrumentNote(uuid: string) {
   return this.http.get(`${API_BASE_V2}${CATALOG}/${INSTRUMENT}/${uuid}/note/`)
  }

  addInstrumentNote(data: any, uuid:string) {
   (data as any) = GeneralHelpers.toMultipartFormData(data);
   return this.http.post(`${API_BASE_V2}${CATALOG}/${INSTRUMENT}/${uuid}/note/`, data)
  }

}
