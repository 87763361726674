import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GeneralHelpers } from '../../helpers/general/general.helper';
import {API_BASE_V1, API_BASE_V2, CATALOG} from '../../constants/general.constants';
import { Observable } from 'rxjs';
import { TrayNoteDTO, TrayNote, TrayNoteResponse } from '../../interfaces/tray-note.interface';

const TRAY = 'tray';
const NOTE = 'note'
const EDITOR = 'editor';
const JOT = 'jot';
const VIDEO = 'video';

@Injectable({
  providedIn: 'root'
})
export class TrayNoteService {

  constructor(private http: HttpClient) { }

  // ────────────────────────────────────────────────────────────────────────────────
  // Create organization note

  create(data: TrayNoteDTO): Observable<any> {
    let { trayUuid, body } = data;
    (body as any) = GeneralHelpers.toMultipartFormData(body);
    return this.http.post(
      `${API_BASE_V2}${CATALOG}/${TRAY}/${trayUuid}/${NOTE}/`,
      body
    );
  }

  generateJWPlayerToken() {
    return this.http.post(`${API_BASE_V1}${EDITOR}/${JOT}/${VIDEO}/`, '');
  }

  uploadVideoToJWStorage(data: any, file: File) {
    const url = 'https://' + data.link.address + data.link.path +
      '?api_format=json&key=' + data.link.query.key +
      '&token=' + data.link.query.token;
    const creds: FormData = new FormData();
    creds.append('file', file);
    return this.http.post(url, creds);
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Read

  get(
    trayUuid: string,
    offset: number = 0,
    limit: number = 25,
    ordering: string = '',
    search: string = '',
  ): Observable<TrayNoteResponse> {
    const options = {
      params: GeneralHelpers.getParams({ offset, limit, ordering, search }),
    };
    return this.http.get<TrayNoteResponse>(
      `${API_BASE_V2}${CATALOG}/${TRAY}/${trayUuid}/${NOTE}/`, options
    );
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Update

  update(data: TrayNoteDTO): Observable<Object> {
    let { trayUuid, noteUuid, body } = data;
    (body as any) = GeneralHelpers.toMultipartFormData(body);
    return this.http
      .put<TrayNote>(`${API_BASE_V2}${CATALOG}/${TRAY}/${trayUuid}/${NOTE}/${noteUuid}/`, body);
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Delete

  delete(trayUuid: string, noteUuid: string) {
    return this.http.delete(
      `${API_BASE_V2}${CATALOG}/${TRAY}/${trayUuid}/${NOTE}/${noteUuid}/`
    );
  }

}
