import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { OrganizationHeaderInterceptor } from './interceptors/organization-header.interceptor';
import { HttpErrorInterceptor } from './interceptors/error.interceptor';
import { RouterModule } from '@angular/router';
import { AuthHttpInterceptor } from '@auth0/auth0-angular';
// ────────────────────────────────────────────────────────────────────────────────
// Confirm dialog
import { ConfirmDialogModule } from './modules/confirm-dialog/confirm-dialog.module';
// ────────────────────────────────────────────────────────────────────────────────
// Image dialog
import { ImageDialogModule } from './modules/image-dialog/image-dialog.module';
// ────────────────────────────────────────────────────────────────────────────────
// Material
import { MessageService } from './services/message/message.service';
// ────────────────────────────────────────────────────────────────────────────────
// Material
import { MatSnackBarModule } from '@angular/material/snack-bar';
// ────────────────────────────────────────────────────────────────────────────────
// Search result dialog
import { IntrumentSearchResultDialogModule } from './modules/intrument-search-result-dialog/intrument-search-result-dialog.module';
// ────────────────────────────────────────────────────────────────────────────────
// Instrument contribution dialog
import { InstrumentContributionDialogModule } from './modules/instrument-contribution-dialog/instrument-contribution-dialog.module';
// ────────────────────────────────────────────────────────────────────────────────
// File upload dialog
import { FileUploadDialogModule } from './modules/file-upload-dialog/file-upload-dialog.module';
// ────────────────────────────────────────────────────────────────────────────────
// Delete tray dialog
import { DeleteTrayDialogModule } from './modules/delete-tray-dialog/delete-tray-dialog.module';
// ────────────────────────────────────────────────────────────────────────────────
// Instrument advanced filters dialog
import { InstrumentsAdvancedFilterDialogModule } from './modules/instruments-advanced-filter-dialog/instruments-advanced-filter-dialog.module';
import { OnboardingService } from './services/onboarding/onboarding.service';
import { InstrumentsTableModule } from "./modules/instruments-table/instruments-table.module";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {MatTabsModule} from "@angular/material/tabs";
import {InstrumentEditModule} from "./modules/instrument-edit/instrument-edit.module";
import {ImagePreviewDialogModule} from "./modules/image-preview-dialog/image-preview-dialog.module";

@NgModule({ exports: [
        InstrumentsTableModule
    ], imports: [CommonModule,
        // Router
        RouterModule,
        // Dialogs
        ConfirmDialogModule,
        ImageDialogModule,
        ImagePreviewDialogModule,
        FileUploadDialogModule,
        IntrumentSearchResultDialogModule,
        InstrumentContributionDialogModule,
        DeleteTrayDialogModule,
        InstrumentsAdvancedFilterDialogModule,
        InstrumentEditModule,
        // ─────────────────────────────────────────────────────────────────
        MatSnackBarModule,
        MatFormFieldModule,
        MatDialogModule,
        MatButtonModule,
        MatTabsModule], providers: [
        OnboardingService,
        MessageService,
        HttpClient,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthHttpInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: OrganizationHeaderInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true,
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class SharedModule { }
