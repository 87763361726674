import { GeneralHelpers } from 'src/app/shared/helpers/general/general.helper';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { ConfirmDialogData } from '../../interfaces/confirm-dialog.interface';
import { ConfirmDialogComponent } from '../../modules/confirm-dialog/confirm-dialog.component';
import { InstrumentContributionDialogComponent } from '../../modules/instrument-contribution-dialog/instrument-contribution-dialog.component';
import { IntrumentSearchResultDialogComponent } from '../../modules/intrument-search-result-dialog/intrument-search-result-dialog.component';
import { MessageService } from '../message/message.service';
import { ImageDialogComponent } from '../../modules/image-dialog/image-dialog.component';
import { FileObject } from '../../interfaces/file.interface';
import { FileUploadDialogComponent } from '../../modules/file-upload-dialog/file-upload-dialog.component';
import { DeleteTrayDialogComponent } from '../../modules/delete-tray-dialog/delete-tray-dialog.component';
import { InstrumentsAdvancedFilterDialogComponent } from '../../modules/instruments-advanced-filter-dialog/instruments-advanced-filter-dialog.component';
import {InstrumentDialog, InstrumentResponse} from '../../interfaces/instrument.interface';
import {ImagePreviewDialogComponent} from "../../modules/image-preview-dialog/image-preview-dialog.component";
@Injectable({
  providedIn: 'root',
})
export class DialogService {
  confirmDialogRef!: any;
  intrumentSearchResultDialogRef!: any;
  instrumentContributionDialogRef!: any;
  fileUploadDialogRef!: any;
  deleteTrayDialogRef!: any;
  instrumentsAdvancedFilterDialogRef!: any;
  watchFileDialogComponentDialogRef!: any;
  messageSubscription!: Subscription;

  constructor(private dialog: MatDialog, private messageService: MessageService,) {
    this.messageSubscription = this.messageService
      .getMessage()
      .subscribe((message: any) => {
        if (message && message.text === 'openInstrumentContributionDialog') {
          if (this.intrumentSearchResultDialogRef !== undefined) {
            this.intrumentSearchResultDialogRef.close();
          }
        }
        if (message && (message.text === 'instrument_created' || message.text === 'instrument_cancel')) {
          if (this.instrumentContributionDialogRef !== undefined) {
            this.instrumentContributionDialogRef.close();
          }
        }
      });
  }

  confirmDialog(data: ConfirmDialogData): Observable<any> {
    this.confirmDialogRef = this.dialog
      .open(ConfirmDialogComponent, {
        data,
        width: '400px',
        disableClose: true,
      })
    return this.confirmDialogRef.afterClosed();
  }

  intrumentSearchResultDialog(data: InstrumentDialog): Observable<any> {
    this.intrumentSearchResultDialogRef = this.dialog
      .open(IntrumentSearchResultDialogComponent, {
        data,
        width: '848px',
        height: "666px",
        disableClose: true,
      })
    return this.intrumentSearchResultDialogRef.afterClosed();
  }

  instrumentContributionDialog(data: any): Observable<any> {
    this.instrumentContributionDialogRef = this.dialog
      .open(InstrumentContributionDialogComponent, {
        data,
        width: '800px',
        disableClose: true,
      })
    return this.instrumentContributionDialogRef.afterClosed();;
  }

  fileUploadDialog(data: any): Observable<any> {
    this.fileUploadDialogRef = this.dialog
      .open(FileUploadDialogComponent, {
        data,
        width: '520px',
        height: "316px",
        disableClose: false,
      })
    return this.fileUploadDialogRef.afterClosed();;
  }

  deleteTrayDialog(data: any): Observable<any> {
    this.deleteTrayDialogRef = this.dialog
      .open(DeleteTrayDialogComponent, {
        data,
        width: '520px',
        height: "355px",
        disableClose: false,
      })
    return this.deleteTrayDialogRef.afterClosed();;
  }

  imagePreviewDialog(data: any): Observable<any> {
    return this.dialog
      .open(ImagePreviewDialogComponent, {
        data,
        maxWidth: '80vw',
        maxHeight: '90vh',
        height: '100%',
        width: '100%',
        panelClass: 'full-screen-modal',
        disableClose: false,
      })
      .afterClosed();
  }

  imageDialog(data: { files: FileObject[], index: number },): Observable<any> {
    return this.dialog
      .open(ImageDialogComponent, {
        data,
        maxWidth: '70vw',
        maxHeight: '76vh',
        height: '100%',
        width: '100%',
        panelClass: 'full-screen-modal',
        disableClose: false,
      })
      .afterClosed();
  }

  imageVisionDialog(data: { files: FileObject[], index: number, vision: boolean}) {
    return this.dialog
      .open(ImageDialogComponent, {
        data,
        maxWidth: '70vw',
        maxHeight: '76vh',
        height: '100%',
        width: '100%',
        panelClass: 'full-screen-modal',
        disableClose: false,
      });
  }

  instrumentsAdvancedFilterDialog(data: any): Observable<any> {
    this.instrumentsAdvancedFilterDialogRef = this.dialog
      .open(InstrumentsAdvancedFilterDialogComponent, {
        data,
        width: '960px',
        height: "754px",
        disableClose: true,
      })
    return this.instrumentsAdvancedFilterDialogRef.afterClosed();;
  }
}
