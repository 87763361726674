import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ACTIVATION_CODE, ADMIN, API_BASE_V1, API_BASE_V2, ORGANIZATION_UUID, USER_ORGANIZATION_LIST } from '../../constants/general.constants';
import { GeneralHelpers } from '../../helpers/general/general.helper';
import { Organization, OrganizationResponse } from '../../interfaces/organization.interface';
import { Observable, shareReplay, Subject } from 'rxjs';
import {CookieService} from "ngx-cookie-service";
import {environment} from "../../../../environments/environment";
import {ActivatedRoute} from "@angular/router";

const ORGANIZATION = 'organization';
const CACHE_SIZE = 1;

@Injectable({
  providedIn: 'root'
})
export class OrganizationsService {

  private cachedOrganizations$!: Observable<OrganizationResponse>;

  public organizationsData: Organization[] = [];

  public organization: any = {
    id: '',
    name: ''
  };

  public organizations: Organization[] | any = []

  public inviteCode: any = null;

  public isTeamMember: boolean = false;

  public $organizationWhenSelectOrganization = new Subject<string>();

  constructor(private readonly http: HttpClient,
              private readonly cookieService: CookieService,
              private readonly route: ActivatedRoute) { }

  getAndSetOrgFromRoute() {
    return new Promise((resolve) => {
      this.route.queryParams.subscribe(params => {
        const orgUUID = params['team'];
        if(orgUUID) {
          this.setOrganizationUuid(orgUUID);
        }
        resolve(orgUUID);
      }, () => {
        resolve(null);
      });
    })
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // get by id

  getById(id: number | string) {
    return this.http.get<Organization>(
      `${API_BASE_V1}${ADMIN}/${ORGANIZATION}/${id}/`
    );
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // get by uuid

  getByUuid(id: number | string) {
    return this.http.get<Organization>(
      `${API_BASE_V1}${ORGANIZATION}/${id}/`
    );
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // get with cache

  get(offset: number = 0,
    limit: number = 25,
    sort: string = '',
    search: string = '',
    is_pagination: boolean = true,) {
    if (!this.cachedOrganizations$) {
      this.cachedOrganizations$ = this.getOrganizations(offset, limit, sort, search, is_pagination).pipe(
        shareReplay(CACHE_SIZE)
      );
    }

    return this.cachedOrganizations$;
  }

  getOrganizations(
    offset: number = 0,
    limit: number = 25,
    sort: string = '',
    search: string = '',
    is_pagination: boolean = true,
  ): Observable<OrganizationResponse> {
    const options = {
      params: GeneralHelpers.getParams({ offset, limit, sort, search, is_pagination }),
    };

    return this.http.get<OrganizationResponse>(
      `${API_BASE_V1}${ADMIN}/${ORGANIZATION}/`, options
    );
  }

  getOrganizationsPerInst(
    medical_instrument_uuid: string
  ): Observable<OrganizationResponse> {
    const options = {
      params: GeneralHelpers.getParams({ medical_instrument_uuid }),
    };

    return this.http.get<OrganizationResponse>(
      `${API_BASE_V2}${ORGANIZATION}/`, options
    );
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // create organizations

  create(data: { data: Organization }): Observable<Object> {
    (data as any) = GeneralHelpers.toMultipartFormData(data.data)
    return this.http
      .post<Organization>(`${API_BASE_V1}${ADMIN}/${ORGANIZATION}/`, data);
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Update

  update(id: number, data: { data: Organization }): Observable<Object> {
    (data as any) = GeneralHelpers.toMultipartFormData(data.data)
    return this.http
      .put<Organization>(`${API_BASE_V1}${ADMIN}/${ORGANIZATION}/${id}/`, data);
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Delete

  delete(uuid: string, body: { is_data_contribute: boolean }): Observable<Object> {
    (body as any) = GeneralHelpers.toMultipartFormData(body);
    return this.http
      .delete<Organization>(`${API_BASE_V2}${ORGANIZATION}/${uuid}/`, { body });
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Public get and save user prganization for internal usage
  getAndSaveUserOrganization(organizationId: string) {
    this.getByUuid(organizationId).subscribe({
      next: (organization: Organization) => {
        this.organization = organization;
      }
    })
  }

  // ─────────────────────────────────────────────────────────────────────────────
  // Set Organization Uuid

  setOrganizationUuid(uuid: string) {
    if (typeof uuid === 'string' && uuid !== '') {
      localStorage.setItem(ORGANIZATION_UUID, uuid);
      this.setActiveOrgToCookies(uuid);
      if(this.organizations.length > 0) {
        const selectedOrg = this.organizations.filter((r: any) => r.uuid === uuid);
        if(selectedOrg.length > 0) {
          this.organization = selectedOrg[0];
          this.setOrganizationInviteCode();
        }
      }
      this.$organizationWhenSelectOrganization.next(uuid);
    }
  }

  // ─────────────────────────────────────────────────────────────────────────────
  // Get Organization Uuid

  getOrganizationUuid(): string {
    const uuid = localStorage.getItem(ORGANIZATION_UUID);
    return uuid ? uuid : '';
  }

  getActiveOrgFromCookies() {
    const org = this.cookieService.get(ORGANIZATION_UUID);
    return org ? org : '';
  }

  setActiveOrgToCookies(org: string) {
    this.cookieService.set(ORGANIZATION_UUID, org,{domain: environment.cookieDomain, path: '/'});
  }

  refreshActiveOrgToCookies() {
    const orgUUID = this.getOrganizationUuid();
    if(orgUUID) {
      this.setActiveOrgToCookies(orgUUID);
    }
  }

  // ─────────────────────────────────────────────────────────────────────
  // Set organiations that are abaialbe for switch in user profile
  setOrganizationUserList(organizations: Organization[]) {
    if (Array.isArray(organizations) && organizations.length > 0) {
      this.organizations = organizations;
      localStorage.setItem(USER_ORGANIZATION_LIST, GeneralHelpers.jsonStringify(organizations));
    }
  }

  getOrganizationUserList(): Organization[] {
    const organizationList = localStorage.getItem(USER_ORGANIZATION_LIST);
    return organizationList ? GeneralHelpers.jsonParse(organizationList) : [];
  }

  // ─────────────────────────────────────────────────────────────────────
  // Set organization invite code
  setOrganizationInviteCode() {
    let code: any = 'No code'
    const organization = this.getOrganizationFromProfile();
    if (organization !== null) {
      code = organization.invitation_code;
    }
    this.inviteCode = code;
  }

  setOrganizationInviteCodeFromHome(code: string)
  {
    this.inviteCode = code
  }

  public getOrganizationFromProfile(): Organization | any {
    const orgList = this.getOrganizationUserList();
    const orgUuid = this.getOrganizationUuid();
    if (orgList.length === 0 && orgUuid === '') {
      return null;
    }
    const currentId = orgUuid ? orgUuid : orgList[0].uuid;
    for (let index = 0; index < orgList.length; index++) {
      const element = orgList[index];
      if (element.uuid === currentId) {
        return element;
      }
    }
    return null;
  }

  // ─────────────────────────────────────────────────────────────────────
  // Get invite link
  getInviteLink() {
    const time = new Date().getTime();
    const hash = this.hashCode(this.getInviteSecret(this.inviteCode, time));
    return `${window.location.origin}/invite?${ACTIVATION_CODE}=${this.inviteCode}&h=${hash}&t=${time}`;
  }

  isInviteLinkExpired(inviteCode: string, time: any, hash: any) {
    if(this.hashCode(this.getInviteSecret(inviteCode, time)) === parseInt(hash, 10)) {
      if(new Date().getTime() - time < 60 * 60 * 24 * 30 * 1000) {
        return  false;
      }
    }
    return true;
  }

  getInviteSecret(inviteCode: string, time: any) {
    const secret = 'hub-2023';
    return inviteCode + secret + time;
  }

  hashCode(str: string) {
    var hash = 0,
      i, chr;
    if (str.length === 0) return hash;
    for (i = 0; i < str.length; i++) {
      chr = str.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr;
      hash |= 0;
    }
    return Math.abs(hash);
  }

}
