
import { HttpParams } from '@angular/common/http';
import {INSTRUMENT_GET_PARAMS, ORGANIZATION_UUID, TEAM_ROLES, USER_ROLES} from '../../constants/general.constants';
import { InstrumentGetParams } from '../../interfaces/instrument.interface';
import { SortRule } from '../../interfaces/sort-rule.interface';
import { User } from '../../interfaces/user.interface';

export const STATUS = [
  {
    value: '',
    viewValue: 'All'
  },
  {
    value: 0,
    viewValue: 'New'
  },
  {
    value: 1,
    viewValue: 'Waiting'
  },
  {
    value: 2,
    viewValue: 'Ready'
  },
  {
    value: 3,
    viewValue: 'Rejected'
  },
  {
    value: 4,
    viewValue: 'Deleted'
  }
]

export const CATALOG_INSTRUMENT_STATUSES = [
  {
    value: 1,
    viewValue: 'Awaiting approval'
  },
  {
    value: 2,
    viewValue: 'Approved'
  },
  {
    value: 3,
    viewValue: 'Rejected'
  },
]

export const CATALOG_INSTRUMENT_STATUSES_NEW = [
  {
    value: '1',
    viewValue: 'Awaiting approval'
  },
  {
    value: '2',
    viewValue: 'Approved'
  },
  {
    value: '3',
    viewValue: 'Rejected'
  },
]

export const INSTRUMENT_NOTE_STATUSES = [
  {
    value: 1,
    viewValue: 'Waiting'
  },
  {
    value: 2,
    viewValue: 'Approved'
  },
  {
    value: 3,
    viewValue: 'Rejected'
  },
]

export const CATALOG_INSTRUMENT_STATUSES_VISIBILITY = [
  {
    value: true,
    viewValue: 'Public'
  },
  {
    value: false,
    viewValue: 'Private'
  },
]

export const TEAM_STATUSES = [
  {
    value: 0,
    viewValue: 'Hold'
  },
  {
    value: 1,
    viewValue: 'Active'
  },
]

export const DEFAULT_CATALOG_INSTRUMENT_SEARCH_PARAMS: any = {
  status: null,
  hasImage: null,
  organization_uuid: null,
  isPublic: null,
  capturedMedicalInstrumentStatus: null,
  medicalInstrumentNoteStatus: null,
  isImagesApproved: null,
  hasCapturedMedicalInstrument: null
}

export const LANGUAGES = [
  {
    id: 'en',
    title: 'English',
    flag: 'us',
  },
  {
    id: 'es',
    title: 'Spanish',
    flag: 'es',
  },
  {
    id: 'de',
    title: 'German',
    flag: 'de',
  },
];


export const MAX_STRING_LENGTH = 2048;

export const MAX_ORG_STRING_LENGTH = 32;

export const MAX_LENGTH_AUTH0_STRING = 1048576;

export const MAX_NUMBER = 2147483647;

export const ONE = 1;

export const ZERO = 0

export const acceptedFileImages: string = 'image/*';
export const acceptedFilesAttachments: string =
  'image/*,video/*,.pdf';
export const acceptedFileImport: string = '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';

export const ORGANIZATION_STORAGE = 'organizations'

export const EMAIL_REGEXP = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export interface GetParams {
  offset?: number;
  limit?: number;
  sort?: string;
  search?: string;
  is_pagination?: boolean;
  status?: string;
  q?: string;
  name?: string;
  name__icontains?: string;
  has_image?: boolean;
  sku?: string;
}

// Supported files
const videoFiles: string = 'webm,mp4,ogg';
const audioFiles: string = 'mp3,acc,wav';
const imageFiles: string = 'jpg,jpeg,gif,tiff,webp,png';
// this files will be open with google preview
const documentFiles: string = 'doc,docx,xls,xlsx,ppt,pptx';
// this files will be open with iframe
const pdfFiles: string = 'pdf';

export class GeneralHelpers {

  static convertS(s: number) {
    const time = new Date(s * 1e3).toISOString().slice(-13, -5)
    const timeData = time.split(':');
    if(timeData[0] === '00') {
      return timeData[2];
    } else {
      return time;
    }
  }

  static jsonStringify(data: any) {
    try {
      const stringifyData: string = JSON.stringify(data);
      return stringifyData;
    } catch (error) {
      const message: string = 'Cannot stringify data';
      console.error(message);
      return message;
    }
  }

  static jsonParse(data: any): any {
    try {
      const parsedData: string = JSON.parse(data);
      return parsedData;
    } catch (error) {
      const message: string = 'Cannot parse data';
      console.error(message);
      return message;
    }
  }

  static getParams(params: GetParams | any): HttpParams {
    let parameters = new HttpParams();
    for (const param in params) {
      if (Object.prototype.hasOwnProperty.call(params, param)) {
        if (params[param] === '' || params[param] === undefined || params[param] === null) {
          continue;
        }
        parameters = parameters.set(`${param}`, `${params[param]}`);
      }
    }

    return parameters;
  }

  static isEmail(value: string): boolean {
    value = value.trim();
    return EMAIL_REGEXP.test(value);
  }

  static toMultipartFormData(object: any): FormData {
    let formData = new FormData();
    for (const key in object) {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        formData.append(key, object[key]);
      }
    }
    return formData;
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Google places
  /**
   * Get the value for a given key in address_components
   *
   * @param {Array} components address_components returned from Google maps autocomplete
   * @param type key for desired address component
   * @returns {String} value, if found, for given type (key)
   */

  static extractFromAddress(
    components: any,
    type: string,
    prop: string = 'long_name'
  ): string | undefined {
    return components
      .filter((component: any) => component.types.includes(type))
      .map((item: any) => item[prop])
      .pop();
  }

  static extractCity(components: any): string | undefined {
    return this.extractFromAddress(components, 'locality');
  }

  static extractCountry(components: any): string | undefined {
    return this.extractFromAddress(components, 'country');
  }

  static extractCountryLocal(components: any): string | undefined {
    return this.extractFromAddress(components, 'country', 'short_name');
  }

  static extractPostalCode(components: any): string | undefined {
    return this.extractFromAddress(components, 'postal_code', 'short_name');
  }

  static extractState(components: any): string | undefined {
    return this.extractFromAddress(components, 'administrative_area_level_2', 'short_name');
  }

  static extractStreet(components: any): string | undefined {
    return this.extractFromAddress(components, 'route', 'short_name');
  }

  static getInstrumentMockData(count: number = 1) {
    const instruments = Array(count);
    for (let index = 0; index < instruments.length; index++) {
      instruments[index] = {
        image: '../../../../assets/images/default-instrument.png',
        vendor: 'V. Mueller',
        sku: 'MO1600-S',
        description: `Super cut Metzenbaum dissecting scissors Curved. Mirror finish. Overall length 7in
      (17.8cm).Super
        cut Metzenbaum dissecting scissors Curved.Mirror finish.Overall length 7in (17.8cm).Super cut Metzenbaum
        dissecting scissors Curved.Mirror finish.Overall length 7in (17.8cm).Super cut Metzenbaum dissecting scissors
        Curved.Mirror finish.Overall length 7in (17.8cm).Super cut Metzenbaum dissecting scissors Curved.Mirror
        finish.
        Overall length 7in (17.8cm).Super cut Metzenbaum dissecting scissors Curved.Mirror finish.Overall length 7in
      (17.8cm).Super cut Metzenbaum dissecting scissors Curved.Mirror finish.Overall length 7in (17.8cm).Super cut
        Metzenbaum dissecting scissors Curved.Mirror finish.Overall length 7in (17.8cm).',
    `,
        count: GeneralHelpers.randomIntFromInterval(0, 10),
        hasComputerVision: Math.random() < 0.5,
        is: (Math.random() + 1).toString(36).substring(2)
      }
    }
    return instruments;
  }

  static randomIntFromInterval(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }
  // ────────────────────────────────────────────────────────────────────────────────

  static disableFormInputs(fields: string[], formName: string, disabled: boolean, context: any): void {
    for (let index = 0; index < fields.length; index++) {
      const element = fields[index];
      if (context[formName] !== undefined) {
        let currentFieldsAccessor = context[formName].get(element);
        // currentFieldsAccessor.setValidators(null);
        disabled ? currentFieldsAccessor.disable() : currentFieldsAccessor.enable()
      }
    }
  }

  static getSort(sort: SortRule): string {
    if (sort.direction === '') {
      return '';
    }
    let sortParam = sort.direction === 'desc' ? '-' : '';
    return `${sortParam}${sort.active}`;
  }

  static setRole(profile: User | any): string[] {
    const roles: any = USER_ROLES;
    const activeOrg = localStorage.getItem(ORGANIZATION_UUID) || '';
    const activeOrgData = profile.organizations.filter((org: any) => org.uuid === activeOrg);
    const permissions = [];
    for (const key in roles) {
      if (Object.prototype.hasOwnProperty.call(roles, key)) {
        const role = roles[key];
        if (profile[key]) {
          permissions.push(role);
        }
      }
    }
    let orgInfo = profile.organizations.length > 0 ? profile.organizations[0] : null;
    if(activeOrgData.length > 0) {
      orgInfo = activeOrgData[0];
    }
    if(orgInfo) {
      for (const key in roles) {
        if (Object.prototype.hasOwnProperty.call(roles, key)) {
          const role = roles[key];
          if (orgInfo[key]) {
            permissions.push(role);
          }
        }
      }
    }
    return permissions;
  }

  static getTeamRole(teamData: Partial<User> | any): { value: string | any, viewValue: string } {
    let teamRole = TEAM_ROLES[0];
    for (let index = 0; index < TEAM_ROLES.length; index++) {
      const element = TEAM_ROLES[index];
      if (element.key && teamData[element.key]) {
        teamRole = element;
      }
    }
    return teamRole;
  }

  static getSortAuth0(sort: SortRule): string {
    if (sort.direction === '') {
      return '';
    }
    let sortParam = sort.direction === 'desc' ? '-1' : '1';
    return `${sort.active}:${sortParam}`;
  }

  static processSearch(seacrh: string = ''): string {
    if (GeneralHelpers.isEmail(seacrh)) {
      return seacrh;
    }
    return seacrh === '' ? '' : seacrh.replace(
      /[&\/\\#,+()$~%'":*?<>{}]/gi,
      ''
    );
  }

  static removeSpaces(val: string) {
    return val.trim();
  }

  static formatBytes(bytes: any, decimals = 2) {
    if (bytes === null || bytes === undefined) {
      return '';
    }
    if (typeof bytes === 'string') {
      return bytes;
    }
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  static getOrganizationFromMasterDataCompany(data: string, whatToget: 'id' | 'name') {
    const splittedData = data.split('|');
    if (Array.isArray(splittedData) && splittedData.length === 2) {
      return whatToget === 'id' ? splittedData[0] : splittedData[1];
    }
    return data;
  }

  static getUserLanguage(): any {
    const english = {
      id: 'en',
      title: 'English',
      flag: 'us',
    };
    return english;
  }

  static removeValidationAndHideField(fields: string[], formName: string, flagsname: string, context: any): void {
    for (let index = 0; index < fields.length; index++) {
      const element = fields[index];
      if (context[formName] !== undefined) {
        let currentFieldsAccessor = context[formName].get(element);
        currentFieldsAccessor.setValidators(null);
        currentFieldsAccessor.updateValueAndValidity();
      }
      context[flagsname][element] = true;
    }
  }

  static filterNullObject(object: any) {
    for (const key in object) {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        if (object[key] === null) {
          delete object[key];
        }
      }
    }
    return object;
  }

  static getWordsFromString(str: string, howMany: number = 5, allowWords: number = 10): string {
    if (str.split(' ').length > allowWords) {
      return `${str.split(' ').slice(0, howMany).join(' ')} ...`;
    } else {
      return str;
    }
  }

  static getStatus(status: string) {
    let result = STATUS.find((element) => {
      return element.value === parseInt(status, 10);
    })
    return result ? result.viewValue : 'Unknown';
  }

  static getInstrumentCatalogStatus(status: string) {
    let result = CATALOG_INSTRUMENT_STATUSES.find((element) => {
      return element.value === parseInt(status, 10);
    })
    return result ? result.viewValue : 'Unknown';
  }

  static booleanToYesNo(data: boolean): string {
    return data ? 'Yes' : 'No';
  }

  static randomNumber() {
    return Math.floor(Math.random() * 100);
  }

  static randomDate(start: any = new Date(2022, 0, 1), end: any = new Date()) {
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
  }
  /**
 * Extract file extension from URL.
 * @param {String} url
 * @returns {String} File extension or empty string if no extension is present.
 */
  static fileTypeFromString(
    data: any
  ): string {
    let url = data?.url;
    if(data?.is_video) {
      return 'video';
    }
    if (url === undefined) {
      return '';
    }
    let fileExtension = ''
    let fileType = '';
    if (url === null) {
      fileExtension = "";
    }
    let index = url.lastIndexOf("/");
    if (index !== -1) {
      url = url.substring(index + 1);
    }
    index = url.indexOf("?");
    if (index !== -1) {
      url = url.substring(0, index);
    }
    index = url.indexOf("#");
    if (index !== -1) {
      url = url.substring(0, index);
    }
    index = url.lastIndexOf(".");
    fileExtension = index !== -1
      ? url.substring(index + 1)
      : "";

    const fileExtensionRegex = RegExp(fileExtension, 'gi');

    if (fileExtensionRegex.test(videoFiles)) {
      fileType = 'video';
    } else if (fileExtensionRegex.test(audioFiles)) {
      fileType = 'audio';
    } else if (fileExtensionRegex.test(imageFiles)) {
      fileType = 'image';
    } else if (fileExtensionRegex.test(documentFiles)) {
      fileType = 'document';
    } else if (fileExtensionRegex.test(pdfFiles)) {
      fileType = 'pdf';
    }

    return fileType;
  }

  static waitForLocalStorage(key: string, cb: any, timer = 1e2): any {
    if (!localStorage.getItem(key)) return (timer = setTimeout(GeneralHelpers.waitForLocalStorage.bind(null, key), 100))
    clearTimeout(timer)
    if (typeof cb !== 'function') return localStorage.getItem(key)
    return cb(localStorage.getItem(key))
  }

  static sortArrayOfObjects<T>(arr: Array<T>, order: 'asc' | 'desc', prop: string): Array<T> {
    if (!Array.isArray(arr) || arr.length === 0) {
      return [];
    }
    return arr.sort((a: any, b: any) =>
      order === 'asc'
        ? a[prop] > b[prop]
          ? 1
          : b[prop] > a[prop]
            ? -1
            : 0
        : a[prop] > b[prop]
          ? -1
          : b[prop] > a[prop]
            ? 1
            : 0
    );
  }

  static buildInstrumentGetParams(offset: number = 0,
    limit: number = 25,
    ordering: string | null = null,
    q: string | null = null,
    is_pagination: boolean = true,
    status: string | null = null,
    has_image: boolean | null = null,
    organization_uuid: string | null = '',
    is_public: boolean | null = null,
    captured_medical_instrument_status: string | null = null,
    medical_instrument_note_status: string | null = null,
    is_images_approved: boolean | null = null,
    has_captured_medical_instrument: boolean | null = null,
    is_common_search: boolean = false) {
    return Object.assign(INSTRUMENT_GET_PARAMS, {
      offset: offset,
      limit: limit,
      ordering: ordering,
      is_pagination: is_pagination,
      status: status,
      has_image: has_image,
      q: q,
      is_public: is_public,
      captured_medical_instrument_status: captured_medical_instrument_status,
      medical_instrument_note_status: medical_instrument_note_status,
      is_images_approved: is_images_approved,
      has_captured_medical_instrument: has_captured_medical_instrument,
      //is_common_search: (!(is_public !== null || captured_medical_instrument_status !== null || medical_instrument_note_status !== null || is_images_approved !== null || has_captured_medical_instrument !== null))
      is_common_search: is_common_search
    } as InstrumentGetParams)
  }

  static mapInstrumentData(row: any) {
    const infoRow = row.description ? row.description.split('||') : (row.name.split('||') || []);
    const infoArr: any = [];
    const infoGMDN: any = {};
    const descArr: any = [];
    const paramsArr: any = [];

    infoRow.forEach((r: any) => {
      const t = r.split('|');
      if (t.length > 1) {
        if (['GMDN Name', 'GMDN Definition', 'GMDN Product Code Name'].includes(t[0].trim())) {
          infoGMDN[t[0].trim().replace('GMDN ', '').replaceAll(' ', '_').toLowerCase()] = t[1].trim();
        } else if (['Device ID', 'Device Description'].includes(t[0].trim())) {
          // erase not need data
        } else {
          infoArr.push({
            key: t[0].trim(),
            value: t[1].trim()
          });
          paramsArr.push(t[0].trim() + ': ' + t[1].trim());
        }
      } else {
        descArr.push(r.trim());
      }
    });
    if (row.vendor && row.vendor.name) {
      row.brand = row.vendor.name;
    }
    row.title = infoRow.length > 0 ? infoRow[0].trim() : (row.name || '');
    row.params = infoArr;
    row.desc = descArr.join('<br>');
    row.titleFull = descArr.join(' ') + ' ' + paramsArr.join(' ');
    row.GMDN = infoGMDN;
    return row;
  }

  static mapNotesData(data: any) {
    const result: any = [];

    data.forEach((r: any, i: number) => {
      let tmpType = 'note';
      if (r.files && r.files.length > 0) {
        if (r.files[0].url && this.detectPdfInURL(r.files[0].url)) {
          tmpType = 'notePdf';
        } else if (r.files[0].is_video) {
          tmpType = 'noteVideo';
        }
        result.push({
          id: r.id,
          arrIndex: i,
          arrLength: data.length,
          src: r,
          srcTrusted: null,
          status: r.status,
          type: tmpType,
          allowEdit: r.allowEdit,
          allowOnlyDelete: false,
          userOwner: r.user_owner,
          uuid: r.uuid,
        });
      } else {
        if (r.description && this.detectSketchFabInURL(r.description)) {
          tmpType = 'noteIframe';
        }
        result.push({
          id: r.id,
          arrIndex: i,
          arrLength: data.length,
          src: r,
          srcTrusted: null,
          type: tmpType,
          allowEdit: r.allowEdit,
          allowOnlyDelete: false,
          userOwner: r.user_owner,
          uuid: r.uuid,
        });
      }
    });

    return result;
  }

  static detectPdfInURL(url: string) {
    if (url.includes('.pdf?')) {
      return true;
    }
    return false;
  }

  static detectSketchFabInURL(url: string) {
    if (url.includes('https://sketchfab.com')) {
      return true;
    }
    return false;
  }

  static getFileName = (name: string) => {
    let timeSpan = new Date().toISOString();
    let sheetName = name || "tray_report";
    let fileName = `${sheetName}-${timeSpan}`;
    return {
      sheetName,
      fileName
    };
  };
}
