import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { USER_LANGUAGE } from '../../constants/general.constants';
import { GeneralHelpers, LANGUAGES } from '../../helpers/general/general.helper';
import { MessageService } from '../message/message.service';

export interface Locale {
  lang: string;
  data: Object;
}

export interface Language {
  id: string;
  title: string;
  flag: string
}

@Injectable({
  providedIn: 'root'
})
export class TranslationsService {
  public selectedLanguage: any;
  public languages = LANGUAGES;
  constructor(private translateService: TranslateService, private messageService: MessageService) {
    translateService.setDefaultLang('en');
    translateService.use(this.getLanguage().id);
  }


  getLanguage(): Language {
    const langFromLocalStorage: any = localStorage.getItem(USER_LANGUAGE);
    if (langFromLocalStorage === null) {
      return this.languages[0];
    } else {
      return GeneralHelpers.jsonParse(langFromLocalStorage);
    }
  }

  setLanguage(id: string): void {
    const lang = this.languages.find((lang: Language) => {
      return id === lang.id;
    })
    if (lang === undefined) {
      let langFromLocalStorage: any = localStorage.getItem(USER_LANGUAGE);
      if (langFromLocalStorage === null) {
        return;
      }

      langFromLocalStorage = GeneralHelpers.jsonParse(langFromLocalStorage);
      this.selectedLanguage = langFromLocalStorage;
      this.translateService.use(langFromLocalStorage.id);
      this.messageService.sendMessage('setLanguage', langFromLocalStorage.id);
    } else {
      localStorage.setItem(USER_LANGUAGE, GeneralHelpers.jsonStringify(lang));
      this.selectedLanguage = lang;
      this.translateService.use(lang.id);
      this.messageService.sendMessage('setLanguage', lang.id);
    }
  }

}
