import { Injectable } from '@angular/core';
import {NgxPermissionsService, NgxRolesService} from 'ngx-permissions';
import { GeneralHelpers } from '../../helpers/general/general.helper';
import { User } from '../../interfaces/user.interface';
import { UsersService } from '../users/users.service';
import {BehaviorSubject, Subject} from 'rxjs';
import { OrganizationsService } from '../organizations/organizations.service';
import { ORGANIZATION_ADMIN, ORGANIZATION_EDITOR } from '../../constants/general.constants';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  public userPermissions: any = [];
  public permissionsSubject$ = new Subject<any>();
  public userProfile!: User;
  public userProfile$ = new BehaviorSubject<any>(null);
  public orgPermission$= new BehaviorSubject<any>(null)
  constructor(
    private readonly permissionsService: NgxPermissionsService,
    private readonly usersService: UsersService,
    private readonly organizationsService: OrganizationsService,
    private readonly rolesServices: NgxRolesService
  ) { }

  loadPermissions() {
    this.getPermissions().subscribe((profile: any) => {
      const permissions = GeneralHelpers.setRole(profile);
      this.processProfile(profile);
      if (Array.isArray(permissions) && permissions.length > 0) {
        this.rolesServices.addRole(permissions[0], permissions);
        this.permissionsService.loadPermissions(permissions);
      }
      this.orgPermission$.next({
        isLayerJotAdmin: (profile.organizations.some((x:any) => x.name === 'LayerJot' && x.is_organization_admin)) || profile.is_superuser
      });
    }, (err) => {
      console.log(err);
    })
  }

  getPermissions() {
    return this.usersService.getProfile();
  }

  processProfile(profile: User) {
    this.userProfile = profile;
    this.setProfileData(profile);
    this.userProfile$.next(profile);
  }

  managePermissions(profile: User) {
    const permissions = GeneralHelpers.setRole(profile)
    this.permissionsService.loadPermissions(permissions);
    this.userPermissions = permissions;
    this.permissionsSubject$.next(permissions);
  }

  private setProfileData(profile: User | any) {
    const orgUuidCookie = this.organizationsService.getActiveOrgFromCookies();
    if(orgUuidCookie) {
      this.organizationsService.setOrganizationUuid(orgUuidCookie);
    }
    const orgUuid = this.organizationsService.getOrganizationUuid();
    const hasOrgList = Array.isArray(profile.organizations) && profile.organizations.length > 0;
    if (!orgUuid && hasOrgList) {
      this.organizationsService.isTeamMember = true;
      this.organizationsService.setOrganizationUuid(profile?.organizations[0]?.uuid);
    }
    if (orgUuid) {
      if(profile?.organizations.filter((org: any) => org.uuid === orgUuid).length === 0) {
        this.organizationsService.setOrganizationUuid(profile?.organizations[0]?.uuid);
      }
      this.organizationsService.isTeamMember = true;
    }
    if (hasOrgList) {
      this.organizationsService.setOrganizationUserList(profile?.organizations);
      this.organizationsService.setOrganizationInviteCode();
      this.organizationsService.getAndSaveUserOrganization(this.organizationsService.getOrganizationUuid());
    }

    this.organizationsService.isTeamMember = false;
  }

  public updatePermissionForOrganization() {
    this.permissionsService.removePermission(ORGANIZATION_ADMIN);
    this.permissionsService.removePermission(ORGANIZATION_EDITOR);
    const organization = this.organizationsService.getOrganizationFromProfile();
    if (organization.is_organization_admin) {
      this.permissionsService.addPermission(ORGANIZATION_ADMIN);
    }
    if (organization.is_organization_editor) {
      this.permissionsService.addPermission(ORGANIZATION_EDITOR);
    }
  }
}
