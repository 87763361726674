import { Component, Inject, OnInit, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileObject } from '../../interfaces/file.interface';
import { MessageService } from '../../services/message/message.service';

@Component({
  selector: 'app-image-dialog',
  templateUrl: './image-dialog.component.html',
  styleUrls: ['./image-dialog.component.scss']
})
export class ImageDialogComponent implements OnInit {

  public noPrevious = false;
  public noNext = false;
  public file!: FileObject;

  onStatusChange = new EventEmitter();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { files: FileObject[], index: number, vision?: boolean},
    private messageService: MessageService
  ) {
    this.setFile()
  }

  ngOnInit() {
  }

  updateFileStatus(fileId: number | undefined, fileIsRemoved = false) {
    this.onStatusChange.emit({ fileId });
  }

  setFile() {
    this.file = this.data.files[this.data.index];
    this.isLast();
    this.isFirst();
  }

  isLast() {
    this.noNext = this.data.files.length === (this.data.index + 1);
  }

  isFirst() {
    this.noPrevious = this.data.index === 0
  }

  goPrevious() {
    if (this.data.index === 0) {
      return;
    }
    this.data.index -= 1;
    this.setFile()
  }

  goNext() {
    if (this.data.index === this.data.files.length) {
      return;
    }
    this.data.index += 1;
    this.setFile()
  }

  close() {
    this.messageService.sendMessage('close_image_dialog')
  }

}
