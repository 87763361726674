import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InformationAndCatalogComponent } from './information-and-catalog/information-and-catalog.component';
import {InstrumentEditComponent} from "./instrument-edit.component";
import {NotesEditComponent} from './notes-edit/notes-edit.component';
import { CvLibraryComponent } from './cv-library/cv-library.component';
import {MatTabsModule} from "@angular/material/tabs";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {ReactiveFormsModule} from "@angular/forms";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";



@NgModule({
  declarations: [
    InstrumentEditComponent,
    InformationAndCatalogComponent,
    NotesEditComponent,
    CvLibraryComponent
  ],
    imports: [
        CommonModule,
        MatTabsModule,
        DragDropModule,
        ReactiveFormsModule,
        MatOptionModule,
        MatSelectModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        MatCheckboxModule,
        MatProgressSpinnerModule
    ]
})
export class InstrumentEditModule { }
