<form
  name="instrumentForm"
  [formGroup]="instrumentForm"
  novalidate
  (ngSubmit)="
    processInstrument(instrumentForm.value, instrumentForm.valid, $event)
  "
  class="w99 mt-2"
>
  <mat-card-content>
    <div
      fxLayout="row"
      fxLayoutAlign="space-between start"
      fxLayoutGap="2%"
    >
      <div [fxFlex]="48">
        <mat-form-field
          class="w100 custom-label"
          floatLabel="always"
          appearance="outline"
          [ngClass]="{ loading: vendorLoadById }"
          [hidden]="instrumentFormFlags.vendor"
        >
          <mat-label>{{
            "INSTRUMENT_FORM.VENDOR.LABEL" | translate
          }}</mat-label>
          <input
            type="text"
            placeholder="{{ 'INSTRUMENT_FORM.VENDOR.PLACEHOLDER' | translate }}"
            aria-label="Vendor"
            matInput
            formControlName="vendor"
            [matAutocomplete]="vendorAutocomplete"
          />
          <mat-autocomplete
            #vendorAutocomplete="matAutocomplete"
            [displayWith]="displayVendor"
          >
            <mat-option
              disabled
              class="loading pt-3 pb-3"
              *ngIf="vendorLoading"
            >
            </mat-option>
            <mat-option disabled *ngIf="noVendorsFound">
              {{ "INSTRUMENT_FORM.VENDOR.NO_DATA" | translate }}
            </mat-option>
            <mat-option
              *ngFor="let vendor of vendorsAutocompleteData | async"
              [value]="vendor"
            >
              {{
                vendor.name
                  ? vendor.name
                  : ("INSTRUMENT_FORM.VENDOR.NO_NAME" | translate)
              }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field
          class="w100 custom-label"
          floatLabel="always"
          appearance="outline"
          [hidden]="instrumentFormFlags.sku"
        >
          <mat-label>{{ "INSTRUMENT_FORM.SKU.LABEL" | translate }}</mat-label>
          <input matInput formControlName="sku" />
          <mat-error *ngIf="instrumentForm.get('sku')?.hasError('required')">
            {{ "INSTRUMENT_FORM.SKU.ERROR.NO_SKU" | translate }}
          </mat-error>
          <mat-error *ngIf="instrumentForm.get('sku')?.hasError('maxlength')">
            {{ "INSTRUMENT_FORM.SKU.ERROR.MAXLENGTH" | translate }}
          </mat-error>
          <mat-error *ngIf="instrumentForm.get('sku')?.hasError('skuExists')">
            {{ "INSTRUMENT_FORM.SKU.ERROR.SKU_EXISTS" | translate }}
          </mat-error>
        </mat-form-field>

<!--        <mat-form-field-->
<!--          class="w100 custom-label"-->
<!--          floatLabel="always"-->
<!--          appearance="outline"-->
<!--          [hidden]="instrumentFormFlags.name"-->
<!--        >-->
<!--          <mat-label> {{ "INSTRUMENT_FORM.NAME.LABEL" | translate }}</mat-label>-->
<!--          <input matInput formControlName="name" />-->
<!--          <mat-error *ngIf="instrumentForm.get('name')?.hasError('required')">-->
<!--            {{ "INSTRUMENT_FORM.NAME.ERROR.REQUIRED" | translate }}-->
<!--          </mat-error>-->
<!--          <mat-error *ngIf="instrumentForm.get('name')?.hasError('maxlength')">-->
<!--            {{ "INSTRUMENT_FORM.NAME.ERROR.MAXLENGTH" | translate }}-->
<!--          </mat-error>-->
<!--          <mat-error *ngIf="instrumentForm.get('name')?.hasError('minlength')">-->
<!--            {{ "INSTRUMENT_FORM.NAME.ERROR.MINLENGTH" | translate }}-->
<!--          </mat-error>-->
<!--        </mat-form-field>-->

        <mat-form-field
          class="w100 custom-label"
          floatLabel="always"
          appearance="outline"
          [hidden]="instrumentFormFlags.name"
        >
          <mat-label>
            {{ "INSTRUMENT_FORM.DESCRIPTION.LABEL" | translate }}</mat-label
          >
          <textarea matInput formControlName="name" rows="5"></textarea>
          <mat-error
            *ngIf="instrumentForm.get('name')?.hasError('required')"
          >
            {{ "INSTRUMENT_FORM.DESCRIPTION.ERROR.REQUIRED" | translate }}
          </mat-error>
          <mat-error
            *ngIf="instrumentForm.get('name')?.hasError('maxlength')"
          >
            {{ "INSTRUMENT_FORM.DESCRIPTION.ERROR.MAXLENGTH" | translate }}
          </mat-error>
          <mat-error
            *ngIf="instrumentForm.get('name')?.hasError('minlength')"
          >
            {{ "INSTRUMENT_FORM.DESCRIPTION.ERROR.MINLENGTH" | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="w100 custom-label" appearance="outline" floatLabel="always" *ngIf="workMode === 'update'">
          <mat-label>
            {{ "INSTRUMENT_FORM.STATUS.LABEL" | translate }}</mat-label
          >
          <mat-select formControlName="status">
            <mat-option *ngFor="let status of statuses" [value]="status.value">
              {{ status.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="w100 custom-label" appearance="outline" floatLabel="always">
          <mat-label>
            {{ "INSTRUMENT_FORM.VISIBILITY.LABEL" | translate }}</mat-label
          >
          <mat-select formControlName="is_public">
            <mat-option
              *ngFor="let statusVisibility of statusesVisibility"
              [value]="statusVisibility.value"
            >
              {{ statusVisibility.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex="48">
        <h3>{{ "INSTRUMENT_FORM.CATALOG_IMAGES" | translate }}</h3>
        <swiper class="small-slider" [pagination]="{ clickable: true }" [navigation]="instrumentData.images.length > 0" (slideChangeTransitionStart)="slideWillChange()" (slideChangeTransitionEnd)="slideDidChange()">
          <ng-template swiperSlide *ngFor="let img of instrumentData.images">
            <div class="image-wrap">
              <img [src]="img.url" onerror="this.onerror=null;this.src='assets/images/no-image-b.jpg';">
            </div>
            <div class="file-actions">
              <button type="button" mat-icon-button matTooltip="Approve photo" color="primary" *ngIf="!img.is_approved">
                <mat-icon aria-label="Approve">done</mat-icon>
              </button>
              <button type="button" mat-icon-button matTooltip="Reject photo" color="warn" (click)="deleteFile(img)">
                <mat-icon aria-label="Reject">delete</mat-icon>
              </button>
            </div>
          </ng-template>
          <ng-template swiperSlide *ngIf="instrumentData.images.length == 0 || instrumentData.image1 || instrumentData.image2">
            <div class="image-wrap item-preview">
              <img [src]="instrumentData.image1" onerror="this.onerror=null;this.src='assets/images/no-image-b.jpg';" (error)="instrumentData.image1 = ''" *ngIf="instrumentData.image1">
              <img [src]="instrumentData.image2" onerror="this.onerror=null;this.src='assets/images/no-image-b.jpg';" (error)="instrumentData.image2 = ''" *ngIf="instrumentData.image2 && !instrumentData.image1">
              <img src="assets/images/no-image-b.jpg" *ngIf="!instrumentData.image1 && !instrumentData.image2 && !!instrumentFile === false">
              <img [src]="getFileUploadPreviewImage(instrumentFile)" *ngIf="!instrumentData.image1 && !instrumentData.image2 && !!instrumentFile === true">
              <div class="item-preview_clean" *ngIf="!instrumentData.image1 && !instrumentData.image2 && !!instrumentFile === true">
                <button type="button" mat-icon-button matTooltip="Delete catalog image" (click)="clearFile()" color="warn">
                  <mat-icon aria-label="Delete">delete</mat-icon>
                </button>
              </div>
              <div class="item-preview_clean" *ngIf="instrumentData.image1 || instrumentData.image2">
                <button type="button" mat-icon-button matTooltip="Delete catalog image" (click)="clearFile(true)" color="warn">
                  <mat-icon aria-label="Delete">delete</mat-icon>
                </button>
              </div>
              <div class="item-preview_upload" *ngIf="!instrumentData.image1 && !instrumentData.image2 && !!instrumentFile === false">
                <button
                  mat-button
                  class="tray-button blue medium"
                  type="button"
                  color="primary"
                  [disabled]="loading"
                  (click)="instrumentDataFile.click()"
                >
                  <span>Upload photo</span>
                </button>
                <input
                  name="upload"
                  hidden
                  #instrumentDataFile
                  type="file"
                  [attr.accept]="acceptedFileImages"
                  id="instrumentDataFile"
                  (change)="handleFileInput($event, 'photo')"
                />
              </div>
            </div>
          </ng-template>
        </swiper>
<!--        <div *ngIf="instrumentData.image1 !== null && instrumentData.image1 !== undefined">-->
<!--          <div class="catalog-image-wrapper p-0 mt-3">-->
<!--            <div-->
<!--              class="catalog-image"-->
<!--              [ngStyle]="{-->
<!--              'background-image': 'url(' + imageSlider.activeImage + ')'-->
<!--            }"-->
<!--            ></div>-->
<!--            <div *ngIf="!imageSlider.activeImage" class="text-center">-->
<!--              <h1 class="display-6">-->
<!--                {{ "INSTRUMENT_FORM.NO_IMAGE" | translate }}-->
<!--              </h1>-->
<!--            </div>-->
<!--            <div-->
<!--              class="position-absolute top-50 start-50 translate-middle w-100 px-3"-->
<!--              fxLayout="row"-->
<!--              fxLayoutAlign="space-between center"-->
<!--            >-->
<!--              <button-->
<!--                type="button"-->
<!--                mat-icon-button-->
<!--                aria-label="Previous image"-->
<!--                class="bg-light"-->
<!--                color="primary"-->
<!--                (click)="goPrevious()"-->
<!--                [disabled]="imageSlider.noPrevious"-->
<!--              >-->
<!--                <mat-icon class="ps-1">arrow_back_ios</mat-icon>-->
<!--              </button>-->
<!--              <button-->
<!--                type="button"-->
<!--                mat-icon-button-->
<!--                aria-label="Next image"-->
<!--                class="bg-light"-->
<!--                color="primary"-->
<!--                (click)="goNext()"-->
<!--                [disabled]="imageSlider.noNext"-->
<!--              >-->
<!--                <mat-icon>arrow_forward_ios</mat-icon>-->
<!--              </button>-->
<!--            </div>-->
<!--          </div>-->
<!--          <mat-form-field class="w100 no-input-underline custom-label mt-2">-->
<!--            <mat-label>-->
<!--              {{ "INSTRUMENT_FORM.SELECT_STATUS" | translate }}</mat-label-->
<!--            >-->
<!--            <mat-select-->
<!--              [value]="fileStatusValue?.value"-->
<!--              (selectionChange)="updateFileStatus($event)"-->
<!--            >-->
<!--              <mat-option-->
<!--                *ngFor="let item of catalogImageStatuses"-->
<!--                [value]="item.value"-->
<!--              >-->
<!--                {{ item.viewValue }}-->
<!--              </mat-option>-->
<!--            </mat-select>-->
<!--          </mat-form-field>-->
<!--        </div>-->

<!--        <div-->
<!--          class="w100"-->
<!--          fxLayout="row"-->
<!--          fxLayoutAlign="start center"-->
<!--          fxLayoutGap="20px"-->
<!--          *ngIf="instrumentData.image1 === null || instrumentData.image1 === undefined"-->
<!--        >-->
<!--          <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px">-->
<!--            <button-->
<!--              type="button"-->
<!--              mat-stroked-button-->
<!--              color="primary"-->
<!--              [class.loading]="loading"-->
<!--              [disabled]="loading"-->
<!--              (click)="instrumentDataFile.click()"-->
<!--            >-->
<!--              <mat-icon>image</mat-icon>-->
<!--              <span *ngIf="!!instrumentFile === false">Upload photo</span>-->
<!--              <span *ngIf="!!instrumentFile === true">-->
<!--            {{ instrumentFile.name }} - -->
<!--                {{ generalHelpers.formatBytes(instrumentFile.size) }}-->
<!--          </span>-->
<!--            </button>-->
<!--            <button-->
<!--              type="button"-->
<!--              mat-stroked-button-->
<!--              color="warn"-->
<!--              *ngIf="!!instrumentFile === true"-->
<!--              (click)="clearFile()"-->
<!--            >-->
<!--              {{ "INSTRUMENT_FORM.REMOVE_FILE" | translate }}-->
<!--            </button>-->
<!--            <input-->
<!--              name="upload"-->
<!--              hidden-->
<!--              #instrumentDataFile-->
<!--              type="file"-->
<!--              [attr.accept]="acceptedFileImages"-->
<!--              id="instrumentDataFile"-->
<!--              (change)="handleFileInput($event, 'photo')"-->
<!--            />-->
<!--          </div>-->
<!--                <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px">-->
<!--                  <button-->
<!--                    type="button"-->
<!--                    mat-stroked-button-->
<!--                    color="primary"-->
<!--                    [class.loading]="loading"-->
<!--                    [disabled]="loading"-->
<!--                    (click)="catalogDataFile.click()"-->
<!--                  >-->
<!--                    <mat-icon>upload_file</mat-icon>-->
<!--                    <span *ngIf="!!catalogFile === false">Upload catalog file</span>-->
<!--                    <span *ngIf="!!catalogFile === true">-->
<!--                      {{ catalogFile.name }} - -->
<!--                      {{ generalHelpers.formatBytes(catalogFile.size) }}-->
<!--                    </span>-->
<!--                  </button>-->
<!--                  <button-->
<!--                    type="button"-->
<!--                    mat-stroked-button-->
<!--                    color="warn"-->
<!--                    *ngIf="!!catalogFile === true"-->
<!--                    (click)="clearFile()"-->
<!--                  >-->
<!--                    {{ "INSTRUMENT_FORM.REMOVE_FILES" | translate }}-->
<!--                  </button>-->
<!--                  <input-->
<!--                    name="upload"-->
<!--                    hidden-->
<!--                    #catalogDataFile-->
<!--                    type="file"-->
<!--                    [attr.accept]="acceptedFileImages"-->
<!--                    id="catalogDataFile"-->
<!--                    (change)="handleFileInput($event, 'catalog')"-->
<!--                  />-->
<!--                </div>-->
<!--        </div>-->


      </div>
    </div>
  </mat-card-content>

  <mat-card-actions align="end">
    <button
      type="submit"
      mat-button
      class="tray-button blue medium"
      aria-label="CREATE AN ORGANIZATION"
      [disabled]="loading"
    >
      <ng-container *ngIf="workMode === 'create'; else elseBlock">
        {{ "INSTRUMENT_FORM.CREATE_INSTRUMENT" | translate }}
      </ng-container>
      <ng-template #elseBlock>{{
        "INSTRUMENT_FORM.UPDATE_INSTRUMENT" | translate
      }}</ng-template>
    </button>
    <button
      class="tray-button grey medium"
      type="button"
      mat-button
      aria-label="CANCEL"
      [disabled]="loading"
      (click)="cancel()"
    >
      {{ "INSTRUMENT_FORM.CANEL" | translate }}
    </button>
  </mat-card-actions>
</form>
