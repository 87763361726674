<div class="container">
  <div class="notes-columns">
    <span class="columns-header">
      Awaiting approval notes
    </span>
    <div class="awaiting-approval-container drag-container">
      <div
        cdkDropList
        #approved="cdkDropList"
        class="drag-n-drop-note"
        [cdkDropListData]="waitingList"
        [cdkDropListConnectedTo]="[waiting]"
        (cdkDropListDropped)="drop($event)"
      >
        <ng-container *ngIf="noNotes; else noNotesFound">
          <div class="drag-note" *ngFor="let note of waitingList" cdkDrag [cdkDragData]="note"></div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="notes-columns">
    <span class="columns-header">
      Visible notes
    </span>
    <div class="approved-container drag-container">
      <div
        cdkDropList
        #waiting="cdkDropList"
        class="drag-n-drop-note"
        [cdkDropListData]="approvedList"
        [cdkDropListConnectedTo]="[approved]"
        (cdkDropListDropped)="drop($event)"
      >
        <div class="drag-note" *ngFor="let note of approvedList" cdkDrag [cdkDragData]="note">
          <div class="notes-container">
            <span class="note-title">
              {{note.title}}
            </span>
            <span class="note-description">
              {{note.description}}
            </span>
            <span class="note-files"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="notes-columns">
    <span class="columns-header">
      Note editor
    </span>
    <div class="form-container">
      <form [formGroup]="notesForm">
      <div class="input-border">
        <label class="border-label">TYPE TITLE HERE*</label>
        <input type="text" class="title-input" placeholder="Title" formControlName="title">
      </div>
      <div class="desc-border">
        <label class="border-label">DESCRIPTION</label>
        <textarea class="title-input desc-area" placeholder="Type your comments here" formControlName="description" ></textarea>
      </div>
      <span class="media-header">Media (PDF,PNG,JPEG,MP4) </span>
      <div class="media-area">
        <input type="file" multiple="multiple" (change)="getMedia($event)" placeholder="upload" formControlName="files">
        <span class="medial-label">Upload</span>
      </div>
      <div class="checkbox-container">
        <mat-checkbox
          color="primary"
          [checked]="true"
          formControlName="is_public"
        >Is public</mat-checkbox>
      </div>
      <div class="buttons-container">
        <button class="reset-button action-button" (click)="resetForm()">Reset</button>
        <button class="add-button action-button" (click)="addNote()">Add</button>
      </div>
      </form>
    </div>
  </div>
</div>

<ng-template #noNotesFound>
  <div class="noNotesFound">
    <p class="columns-header">Notes not found</p>
  </div>
</ng-template>
