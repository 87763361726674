import {API_BASE_V2, HUB, INSTRUMENTS, TRAY} from './../../constants/general.constants';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GeneralHelpers } from '../../helpers/general/general.helper';
import { CATALOG } from '../../constants/general.constants';
import {Observable} from 'rxjs';
import { Instrument, InstrumentCatalog, InstrumentGetParams, InstrumentResponse } from '../../interfaces/instrument.interface';
import { FileObject, FileObjectResponse } from '../../interfaces/file.interface';
import {environment} from "../../../../environments/environment";
import {Sort} from "@angular/material/sort";

const INSTRUMENT = "instrument";
const FILE = 'file'
const SECTION = "section";
const ASSEMBLY = "assembly";
const ORGANIZATION = "organization";

@Injectable({
  providedIn: 'root'
})
export class InstrumentsService {

  constructor(private readonly http: HttpClient) { }

  // ────────────────────────────────────────────────────────────────────────────────
  // Create

  create(data: InstrumentCatalog): Observable<Object> {
    (data as any) = GeneralHelpers.toMultipartFormData(data);
    return this.http
      .post<InstrumentCatalog>( `${API_BASE_V2}${CATALOG}/${INSTRUMENT}/`, data);
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Read V1

  get(
    params: InstrumentGetParams
  ): Observable<InstrumentResponse> {
    let { offset, limit, ordering, search, is_pagination,
      status, has_image, organization_uuid, q,
      is_common_search, captured_medical_instrument_status,
      medical_instrument_note_status, is_public, is_images_approved,
      has_captured_medical_instrument } = params;
    const options = {
      params: GeneralHelpers.getParams({ offset, limit, ordering, search, is_pagination,
        status, has_image, organization_uuid, q, is_common_search, captured_medical_instrument_status,
        medical_instrument_note_status, is_public, is_images_approved, has_captured_medical_instrument }),
    };

    return this.http.get<InstrumentResponse>(
      `${API_BASE_V2}${CATALOG}/${INSTRUMENT}/`, options
    );
  }

  getBySKU(sku: string): Observable<InstrumentResponse> {
    const options = {
      params: GeneralHelpers.getParams({ q: sku }),
    };
    return this.http.get<InstrumentResponse>(
      `${API_BASE_V2}${CATALOG}/${INSTRUMENT}/`, options
    );
  }

  getSectionInstruments(
    trayUuid: string,
    traySectionUuid: string,
    offset: number = 0,
    limit: number = 50,
    ordering: string | null = null,
    // search: string | null = null,
    // is_pagination: boolean = true,
    // status: string | null = null,
    // has_image: boolean | null = null,
    // organization_uuid: string | null = '',
    // sku: string[] | any = []
  ): Observable<InstrumentResponse> {
    const options = {
      params: GeneralHelpers.getParams({
        offset, limit, ordering, //search, is_pagination, status, has_image, organization_uuid, sku,
      }),
    };

    return this.http.get<InstrumentResponse>(
      `${API_BASE_V2}${HUB}/${CATALOG}/${TRAY}/${trayUuid}/${SECTION}/${traySectionUuid}/${INSTRUMENT}/${ASSEMBLY}/`, options
    );
  }

  // ─────────────────────────────────────────────────────────────────────
  // Get files V2

  files(
    uuid: string,
    params: InstrumentGetParams,
  ): Observable<FileObjectResponse> {
    let { offset, limit, is_pagination, is_approved } = params;

    const options = {
      params: GeneralHelpers.getParams({ offset, limit, is_pagination, is_approved }),
    };

    return this.http.get<FileObjectResponse>(
      `${API_BASE_V2}${CATALOG}/${INSTRUMENT}/${uuid}/${FILE}/`, options
    );
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Get by id

  getById(
    uuid: string | number,
  ): Observable<Instrument> {
    return this.http.get<Instrument>(
      `${API_BASE_V2}${CATALOG}/${INSTRUMENT}/${uuid}/`
    );
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Update

  update(uuid: number, data: InstrumentCatalog): Observable<Object> {
    (data as any) = GeneralHelpers.toMultipartFormData(data);
    return this.http
      .put<InstrumentCatalog>(`${API_BASE_V2}${CATALOG}/${INSTRUMENT}/${uuid}/`, data);
  }

  // ─────────────────────────────────────────────────────────────────────
  // Update medical instrument files v2

  updateFile(medicalInstrumentUuid: string, fileUuid: string, data:any): Observable<Object> {
    (data as any) = GeneralHelpers.toMultipartFormData(data);

    return this.http
      .put<FileObject>(`${API_BASE_V2}${CATALOG}/${INSTRUMENT}/${medicalInstrumentUuid}/${FILE}/${fileUuid}/`, data);
  }

  // ─────────────────────────────────────────────────────────────────────
  // Delete medical instrument files v2

  deleteFile(medicalInstrumentUuid: string, fileUuid: string): Observable<Object> {
    return this.http
      .delete<FileObject>(`${API_BASE_V2}${CATALOG}/${INSTRUMENT}/${medicalInstrumentUuid}/${FILE}/${fileUuid}/`);
  }

  // ─────────────────────────────────────────────────────────────────────
  // Patch

  patch(data: {
    action: string,
    uuids: string[]
  }): Observable<Object> {
    (data as any) = GeneralHelpers.toMultipartFormData(data);
    return this.http
      .patch<any>(`${API_BASE_V2}${CATALOG}/${INSTRUMENT}/`, data);
  }


  // ────────────────────────────────────────────────────────────────────────────────
  // Delete

  delete(uuid: string) {
    return this.http.delete(
      `${API_BASE_V2}${CATALOG}/${INSTRUMENT}/${uuid}/`
    );
  }

  getTypeaheadKeywords() {
    return this.http.get(
      '/assets/keywords/data.json',
      {responseType: 'json'});
  }

  getRandomImage(query = '', topics = '', orientation = 'portrait') {
    return this.http.get(
      'https://api.unsplash.com/photos/random/?client_id='
      + environment.unsplashAccessKey + '&orientation=' + orientation + '&query=' + query + '&topics=' + topics + '&content_filter=high',
      {responseType: 'json'});
  }

  getNewHubInstruments(params: InstrumentGetParams) {
    let {  is_lazy_loading_pagination ,q,offset
      , limit,is_pagination, is_common_search
      , status,ordering,medical_instrument_note_status, organization_uuid } = params;
    const options = {
      params: GeneralHelpers.getParams({
        is_lazy_loading_pagination, q, offset,limit, is_pagination, is_common_search, status, ordering, medical_instrument_note_status, organization_uuid
      }),
    };
    return this.http.get(`${API_BASE_V2}${CATALOG}/${INSTRUMENT}/`, options);
  }

  getPrivateInstruments(params: InstrumentGetParams) {
    let {  is_lazy_loading_pagination ,q,offset
      , limit,is_pagination, has_private_images, has_private_notes, is_private, is_modified, is_omit_friendly_organization_files } = params;
    const options = {
      params: GeneralHelpers.getParams({
        is_lazy_loading_pagination, q, offset,limit, is_pagination, has_private_images, has_private_notes, is_private, is_modified, is_omit_friendly_organization_files
      }),
    };
    return this.http.get(`${API_BASE_V2}${ORGANIZATION}/${INSTRUMENT}/`, options);
  }

  mapHubInstruments(results: any) {
    results.forEach((result: any, i: number) => {
      results[i].mainImage = results[i]?.image1 ?? null;
      results[i].images = [];
      if (result.files.length > 0) {
        result.files.forEach((file: any) => {
          if (file.is_image && file.is_approved) {
            results[i].images.push(file.url);
          }
        });
      }
      if(results[i].images.length > 0) {
        results[i].mainImage = results[i].images[0];
      }
    });
    return results;
  }

  modifyNewHubInstrument(payload: any, id:any) {
      (payload as any) = GeneralHelpers.toMultipartFormData(payload)
      return this.http.put(`${API_BASE_V2}${CATALOG}/${INSTRUMENT}/${id}/`, payload);

  }

  getNewHubPhotos(params: InstrumentGetParams) {
    let { offset, limit,is_pagination, is_common_search, status,is_approved, ordering } = params;
    const options = {
      params: GeneralHelpers.getParams({
         offset,limit, is_pagination, is_common_search, is_approved, ordering
      }),
    };
    return this.http.get(`${API_BASE_V2}${CATALOG}/${INSTRUMENTS}/files/`,options);

  }

  getInstrumentFiles(instrumentUuid:any) {
    return this.http.get(`${API_BASE_V2}${CATALOG}/${INSTRUMENT}/${instrumentUuid}/file/`);
  }

  approveInstrumentPhoto(params:any, data:{is_approved:true}) {
    (data as any) = GeneralHelpers.toMultipartFormData(data);
    return this.http.put(`${API_BASE_V2}${CATALOG}/${INSTRUMENT}/${params.instrument_uuid}/file/${params.uuid}/`, data);
  }

  updateInstrumentPhotoIndex(params: any, data:any) {
    (data as any) = GeneralHelpers.toMultipartFormData(data)
    return this.http.patch(`${API_BASE_V2}${CATALOG}/${INSTRUMENT}/${params}/file/`, data)
  }

  deleteInstrumentPhoto(params:any) {
    return this.http.delete(`${API_BASE_V2}${CATALOG}/${INSTRUMENT}/${params.instrument_uuid}/file/${params.uuid}/`);
  }

  updateInstrumentPhoto(data:any, uuid:any) {
    return this.http.put(`${API_BASE_V2}${CATALOG}/${INSTRUMENT}/${uuid}/`, data);
  }

  updateFileStatus(data:any, is_approved:any, instrumentUuid:any) {
    return this.http.put(`${API_BASE_V2}${CATALOG}/${INSTRUMENT}/${instrumentUuid}/file/${data.uuid}/`, is_approved);
  }

  hideImage(data:any, is_hidden:any, instrumentUuid:any) {
    return this.http.put(`${API_BASE_V2}${CATALOG}/${INSTRUMENT}/${instrumentUuid}/file/${data.uuid}/`, is_hidden);
  }

  getSortDirection(sort: Sort) {
    if(sort?.direction === 'desc') {
      return '-' + sort?.active;
    }
    return sort?.active;
  }

}
