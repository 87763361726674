import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// ────────────────────────────────────────────────────────────────────────────────
// Component
import { DeleteTrayDialogComponent } from './delete-tray-dialog.component';
// ────────────────────────────────────────────────────────────────────────────────
// Material
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
// ────────────────────────────────────────────────────────────────────────────────
// Flex layout
import { FlexLayoutModule } from '@angular/flex-layout';
// ────────────────────────────────────────────────────────────────────────────────
// Forms Module
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatInputModule,
    // Layout
    FlexLayoutModule,
    // Form
    ReactiveFormsModule,
    FormsModule,
    // Translations
    TranslateModule.forChild(),
  ],
  declarations: [DeleteTrayDialogComponent],
})
export class DeleteTrayDialogModule { }
