import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { OrganizationsService } from '../services/organizations/organizations.service';
import { MemoryStorage } from '../services/memory-storage/memory-storage.service';
import { User } from '../interfaces/user.interface';
import { UsersService } from '../services/users/users.service';

const localStorage = new MemoryStorage('localStorage');

@Injectable()
export class OrganizationUUIDControlGuard  {
  constructor(
    private readonly router: Router,
    private readonly organizationsService: OrganizationsService,
    private readonly usersService: UsersService,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    routerState: RouterStateSnapshot
  ): boolean | Observable<boolean> {
    const subject = new Subject<boolean>();

    this.usersService.getProfile().subscribe({
      next: (profile: User) => {
        const isUUIDPresented = !!this.organizationsService.getOrganizationUuid();

        if (!isUUIDPresented) {
          this.router.navigate(['/not-authenticated']);
        }

        subject.next(isUUIDPresented);
      },
      error: (error) => {
        this.router.navigate(['/not-authenticated']);

        subject.next(false);
      },
      complete: () => { },
    });

    return subject.asObservable();
  }
}
