import { Component, Input, OnInit } from '@angular/core';
import { InstrumentCatalog } from '../../interfaces/instrument.interface';

@Component({
  selector: 'app-instrument-search-item',
  templateUrl: './instrument-search-item.component.html',
  styleUrls: ['./instrument-search-item.component.scss']
})
export class InstrumentSearchItemComponent implements OnInit {

  @Input() instrumentData!: InstrumentCatalog;

  mainImage: any;

  constructor() { }

  ngOnInit() {
    if(this.instrumentData) {
      this.mainImage = this.instrumentData.image1 ? this.instrumentData.image1 : this.instrumentData.image2;
      if(this.instrumentData.files) {
        const images = this.instrumentData.files.filter(item => item.is_image);
        if(images.length > 0) {
          this.mainImage = images[0].url;
        }
      }
    }
  }

}
