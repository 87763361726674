import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogData } from '../../interfaces/confirm-dialog.interface';
import { MessageService } from '../../services/message/message.service';

@Component({
  selector: 'app-delete-tray-dialog',
  templateUrl: './delete-tray-dialog.component.html',
  styleUrls: ['./delete-tray-dialog.component.scss']
})
export class DeleteTrayDialogComponent implements OnInit {
  public trayNameForDeletion: string = '';
  public messageShown: boolean = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData, private messageService: MessageService) { }

  ngOnInit() {
  }

  showDeleteMessage() {
    this.messageShown = true;
    setTimeout(() => {
      this.close();
    }, 5e3)
  }

  close() {
    this.messageService.sendMessage('close_tray_delete_dialog')
  }

}

