<mat-toolbar *ngIf="showHeader" [ngClass]="{'darkTheme': darkTheme}">
  <mat-toolbar-row class="header">
    <div class="container" fxLayout="row" fxLayoutAlign="center">
      <div fxLayout="row" fxLayoutAlign="start center">
        <a
          class="logo"
          href="javascript:void(0)"
          rel="noreferrer noopener"
          [routerLink]="'/'"
        >
          <img src="assets/images/hub-logo.svg">
        </a>
      </div>
<!--      <div class="container" fxLayout="row" fxLayoutAlign="space-between center">-->
<!--        <div class="sub-menu">-->
<!--          <ng-container *ngFor="let item of navigation">-->
<!--            <ng-container *ngxPermissionsOnly="item.permission">-->
<!--              <button-->
<!--                mat-button-->
<!--                [routerLink]="item.link"-->
<!--                routerLinkActive="active"-->
<!--                #rla="routerLinkActive"-->
<!--                (click)="openNavLink(item.link)"-->
<!--              >-->
<!--                {{ item.label }}-->
<!--              </button>-->
<!--            </ng-container>-->
<!--          </ng-container>-->
<!--        </div>-->
<!--      </div>-->
      <div
        fxFlex="100"
        class="user-info"
        fxLayout="row"
        fxLayoutAlign="end center"
        *ngIf="permissionsService.userProfile"
        fxLayoutGap="20px"
      >
        <div [matMenuTriggerFor]="menu" *ngIf="permissionsService.userProfile">
          <img src="assets/images/burger.svg">
        </div>
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
<mat-menu #menu="matMenu">
  <div class="menu-content">
    <div class="menu-item title">
      <span class="menu-icon"><img src="assets/images/menu-icon-help.svg"></span>
      Help
    </div>
    <div class="menu-item">
      <button mat-menu-item (click)="openNavLink('https://support.layerjot.com')">
        <span>Help center</span>
      </button>
    </div>
    <div class="menu-item">
      <a
        mat-menu-item
        target="_blank"
        href="https://support.layerjot.com/portal/en/newticket?departmentId=722191000000006907&layoutId=722191000000074011"
      >
        <span>Contact support</span>
      </a>
    </div>
    <mat-divider></mat-divider>
    <div class="menu-item title">
      <button mat-menu-item (click)="logout()">
        <span class="menu-icon"><img src="assets/images/menu-icon-logout.svg"></span> Logout
      </button>
    </div>
  <!-- <p class="menu-title">Language:</p> -->
<!--  <mat-form-field-->
<!--    class="custom-label w100"-->
<!--    floatLabel="always"-->
<!--    (click)="preventMenuClosing($event)"-->
<!--  >-->
<!--    <mat-label>{{ "HEADER.MENU.LANGUAGE.LABEL" | translate }}</mat-label>-->
<!--    <mat-select-->
<!--      (selectionChange)="setLanguage($event)"-->
<!--      [(value)]="translationsService.getLanguage().id"-->
<!--    >-->
<!--      <mat-option *ngFor="let lang of languages" [value]="lang.id">-->
<!--        {{ lang?.title }}-->
<!--      </mat-option>-->
<!--    </mat-select>-->
<!--  </mat-form-field>-->
<!--  <mat-divider-->
<!--    *ngIf="organizationsService?.organizations?.length"-->
<!--  ></mat-divider>-->
<!--  <p class="menu-title">{{ "HEADER.MENU.TITLE" | translate }}</p>-->
<!--  <button mat-menu-item (click)="printTheMat()">-->
<!--    <span>{{ "HEADER.MENU.PRINT" | translate }}</span>-->
<!--  </button>-->
<!--  <button mat-menu-item (click)="trayOnboarding()">-->
<!--    <span> {{ "HEADER.MENU.TUTORIAL" | translate }}</span>-->
<!--  </button>-->
<!--  <a mat-menu-item href="https://support.layerjot.com" target="_blank">-->
<!--    <span>{{ "HEADER.MENU.HELP" | translate }}</span>-->
<!--  </a>-->
<!--  <a-->
<!--    mat-menu-item-->
<!--    href="mailto:{{supportEmail}}?subject=HUB Admin support request [Web]"-->
<!--  >-->
<!--    <span>{{ "HEADER.MENU.SUPPORT" | translate }}</span>-->
<!--  </a>-->
<!--  <button mat-menu-item href="https://www.layerjot.com/legal" target="_blank">-->
<!--    <span>{{ "HEADER.MENU.LEGAL" | translate }}</span>-->
<!--  </button>-->
<!--  <button mat-menu-item (click)="logout()">-->
<!--    <mat-icon>logout</mat-icon> <span>{{ "HEADER.MENU.LOGOUT" | translate }}</span>-->
<!--  </button>-->
  </div>
</mat-menu>
