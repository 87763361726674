import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
// Material UI
import { MatToolbarModule } from '@angular/material/toolbar';
// Flex layout
import { FlexLayoutModule } from '@angular/flex-layout';
// Router
import { RouterModule } from '@angular/router';
// ─────────────────────────────────────────────────────────────────────────────
// Translations
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
  imports: [
    CommonModule,
    // Material
    MatToolbarModule,
    FlexLayoutModule,
    // Router
    RouterModule,
    // Translations
    TranslateModule.forChild(),],
  declarations: [FooterComponent],
  exports: [FooterComponent],
})
export class FooterModule { }
