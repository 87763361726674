

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// ────────────────────────────────────────────────────────────────────────────────
// Component
import { FileUploadDialogComponent } from './file-upload-dialog.component';
// ────────────────────────────────────────────────────────────────────────────────
// Material
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
// ────────────────────────────────────────────────────────────────────────────────
// Flex layout
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    // Layout
    FlexLayoutModule,
    // Translations
    TranslateModule.forChild(),
  ],
  declarations: [FileUploadDialogComponent],
})
export class FileUploadDialogModule { }
