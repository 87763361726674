import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {InstrumentDialog} from "../../interfaces/instrument.interface";

@Component({
  selector: 'app-instrument-contribution-dialog',
  templateUrl: './instrument-contribution-dialog.component.html',
  styleUrls: ['./instrument-contribution-dialog.component.scss']
})
export class InstrumentContributionDialogComponent implements OnInit {

  afterSubmitMessage = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: InstrumentDialog | any) {
    if(data && data.message) {
      this.afterSubmitMessage = data.message;
    }
  }

  ngOnInit() {

  }

}
