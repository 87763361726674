<div class="table-wrapper mat-elevation-z0 w100">
<ng-container *ngIf="tableValue === 'catalog'">
    <div class="toolbar" fxLayout="row" fxLayoutAlign="space-between center">
      <div
        class="toolbar-filter w100"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="10px"
      >
        <div class="grid w100">
          <div class="right">
            <div class="first-row">
              <span class="teamName" *ngIf="organizationsService.organization?.name">{{ organizationsService.organization?.name }} Private Catalog</span>
            </div>
            <div>
              <div fxFlex="86" fxLayoutAlign="space-between center" fxLayoutGap="10px">
                <div class="search-input-wrapper" fxFlex="30">
                  <mat-icon>search</mat-icon>
                  <input
                    class="form-control search-input full"
                    type="text"
                    placeholder="Search"
                    aria-label="Search"
                    #search
                    [formControl]="searchControl"
                  />
                </div>
                <span class="status-container">
                  <span class="status-filter">
                    <mat-radio-group [color]="'primary'" [value]="instFilters['catalog']?.filters.content" (change)="instQFilterChange('content', $event)" aria-label="Select an filter">
                      <mat-radio-button value="1" >Private instruments</mat-radio-button>
                      <mat-radio-button value="2" >Modified instruments</mat-radio-button>
                      <mat-radio-button value="3" >Private photos</mat-radio-button>
                      <mat-radio-button value="4" >Private Notes</mat-radio-button>
                    </mat-radio-group>
                  </span>
                </span>
              </div>
              <div fxFlex="14" fxLayoutAlign="end center" fxLayoutGap="10px">
                <div class="add-item">
                  <button mat-button class="blue-button" (click)="addInstrument()">
                    <mat-icon>add</mat-icon> Add an item
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="loading-shade" *ngIf="loading">
      <mat-spinner *ngIf="loading"></mat-spinner>
    </div>
    <div class="w100">
    <div
      class="table-container"
      infiniteScroll
      [infiniteScrollDistance]="1"
      [infiniteScrollThrottle]="300"
      (scrolled)="onScroll()"
      [scrollWindow]="false"
    >
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)"
           class="mat-elevation-z8">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef class="w-700" >
          Instrument
        </th>
        <td mat-cell *matCellDef="let element" class="w-700">
          <img src="assets/images/no-image.svg" class="table-image" alt="instrument" *ngIf="!element?.mainImage">
          <img [src]="element?.mainImage" class="table-image" alt="instrument" *ngIf="element?.mainImage">
          {{element.name}}</td>
      </ng-container>

      <ng-container matColumnDef="vendor__name">
        <th mat-header-cell *matHeaderCellDef>
          Manufacturer
        </th>
        <td mat-cell *matCellDef="let element"><span class="manufacturer-outline">{{element?.vendor?.name}}</span></td>
      </ng-container>

      <ng-container matColumnDef="sku">
        <th mat-header-cell *matHeaderCellDef>
          SKU
        </th>
        <td mat-cell *matCellDef="let element">{{element.sku}} </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef >
          Actions
        </th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button matTooltip="Edit intrument" color="primary" (click)="openUpdateCatalog(element, 0)">
          <mat-icon aria-label="Edit">edit</mat-icon>
        </button>
          <button mat-icon-button matTooltip="Delete intrument"  color="warn" (click)="deleteCatalogInstrument(element)" *ngIf="element.organization_owner === activeOrgID">
            <mat-icon aria-label="Delete">delete</mat-icon>
          </button> </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>
    </table>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="tableValue==='instruments-queue'">
  <div class="toolbar" fxLayout="row" fxLayoutAlign="space-between center">
    <div
      class="toolbar-filter w100"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="10px"
    >
      <div class="grid w100">
        <div class="right">
          <div class="first-row">
            <span class="teamName" *ngIf="organizationsService.organization?.name">{{ organizationsService.organization?.name }} Instruments Queue</span>
          </div>
          <div>
            <div fxFlex="50" fxLayoutAlign="space-between center" fxLayoutGap="20px">
              <span class="status-container">
               <span class="status-tooltip">Filter by status: </span>
                <span class="status-filter">
                  <mat-radio-group [color]="'primary'" [value]="instFilters['instruments-queue']?.filters.status" (change)="instQFilterChange('status', $event)" aria-label="Select an filter">
                    <mat-radio-button value="1" >Awaiting Approval</mat-radio-button>
                    <mat-radio-button value="3" >Rejected</mat-radio-button>
                  </mat-radio-group>
                </span>
              </span>
            </div>
            <div fxFlex="50" fxLayoutAlign="end center" fxLayoutGap="20px">
              <button mat-button class="blue-button" (click)="addInstrument()">
                <mat-icon>add</mat-icon> Add an item
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="loading-shade" *ngIf="loading">
    <mat-spinner *ngIf="loading"></mat-spinner>
  </div>
  <div class="w100">
    <div
      class="table-container"
      infiniteScroll
      [infiniteScrollDistance]="1"
      [infiniteScrollThrottle]="300"
      (scrolled)="onScroll()"
      [scrollWindow]="false"
    >
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)"
           class="mat-elevation-z8" *ngIf="dataSource">
      <ng-container matColumnDef="name">
        <th mat-header-cell class="w-600" *matHeaderCellDef mat-sort-header >
          Instrument
        </th>
        <td mat-cell class="w-600" *matCellDef="let element" >
          <img src="assets/images/no-image.svg" class="table-image" alt="instrument" *ngIf="!element?.mainImage">
          <img [src]="element?.mainImage" class="table-image" alt="instrument" *ngIf="element?.mainImage">
          {{element.name}}</td>
      </ng-container>
      <ng-container matColumnDef="vendor__name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Manufacturer
        </th>
        <td mat-cell *matCellDef="let element"><span class="manufacturer-outline">{{element?.vendor?.name}}</span></td>
      </ng-container>
      <ng-container matColumnDef="sku">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >
          SKU
        </th>
        <td mat-cell *matCellDef="let element">{{element.sku}} </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th class="w-150" mat-header-cell *matHeaderCellDef >
          Status
        </th>
        <td class="w-150" mat-cell *matCellDef="let element">{{statusMapping(element.status)}}</td>
      </ng-container>
      <ng-container matColumnDef="date_created">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >
          Date
        </th>
        <td mat-cell *matCellDef="let element">{{element.date_created |date:'MM-dd-yy'}}</td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th class="w-150" mat-header-cell *matHeaderCellDef >
          Actions
        </th>
        <td class="w-150" mat-cell *matCellDef="let element">
          <button mat-icon-button matTooltip="Accept intrument" color="primary" (click)="approveInstrument(element)">
            <img src="assets/images/accept-photo.svg" alt="">
          </button>
          <button mat-icon-button matTooltip="Edit intrument" color="primary" (click)="openUpdateCatalog(element, 0)">
            <mat-icon aria-label="Edit">edit</mat-icon>
          </button>
          <button mat-icon-button matTooltip="Delete intrument"  color="warn" (click)="deleteCatalogInstrument(element)">
            <mat-icon aria-label="Delete">delete</mat-icon>
          </button> </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
    </table>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="tableValue==='photo-queue'">
  <div class="toolbar" fxLayout="row" fxLayoutAlign="space-between center">
    <div
      class="toolbar-filter w100"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="10px"
    >
      <div class="grid w100">
        <div class="right">
          <div class="first-row">
            <span class="teamName" *ngIf="organizationsService.organization?.name">{{ organizationsService.organization?.name }} Photo Queue</span>
          </div>
          <div>
            <div fxFlex="86" fxLayoutAlign="space-between center" fxLayoutGap="10px">
              <div class="search-input-wrapper" fxFlex="30">
                <mat-icon>search</mat-icon>
                <input
                  class="form-control search-input full"
                  type="text"
                  placeholder="Search"
                  aria-label="Search"
                  #search
                  [formControl]="searchControl"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="loading-shade" *ngIf="loading">
    <mat-spinner *ngIf="loading"></mat-spinner>
  </div>
  <div class="w100">
    <div
      class="table-container"
      infiniteScroll
      [infiniteScrollDistance]="1"
      [infiniteScrollThrottle]="100"
      (scrolled)="onScroll()"
      [scrollWindow]="false"
    >
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)"
           class="mat-elevation-z8">
      <ng-container matColumnDef="photo">
        <th mat-header-cell *matHeaderCellDef class="table-photo-width" >
          New Photos
        </th>
        <td mat-cell *matCellDef="let element" class="table-photo-width">
          <div class="catalog-image-wrap" (click)="openImagePreview(element.url)">
            <img src="{{element.url}}" alt="preview">
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="organization_medical_instrument__medical_instrument__name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-500">
          Instrument
        </th>
        <td mat-cell *matCellDef="let element" class="w-500">{{element.medical_instrument.name}}</td>
      </ng-container>
      <ng-container matColumnDef="organization_medical_instrument__medical_instrument__vendor__name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Manufacturer
        </th>
        <td mat-cell *matCellDef="let element"><span class="manufacturer-outline">{{element.medical_instrument.vendor}}</span></td>
      </ng-container>
      <ng-container matColumnDef="organization_medical_instrument__medical_instrument__sku">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          SKU
        </th>
        <td mat-cell *matCellDef="let element">{{element.medical_instrument.sku}} </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef >
          Status
        </th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'accepted-text': element.is_approved, 'deleted': element?.is_deleted}">
          <ng-container *ngIf="!element?.is_deleted">
            {{element.is_approved ? 'Approved' : 'Awaiting Approval'}}
          </ng-container>
          <ng-container *ngIf="element?.is_deleted">
            Deleted
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="date_created">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >
          Date
        </th>
        <td mat-cell *matCellDef="let element">{{element.date_created |date:'MM-dd-yy'}}</td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef >
          Actions
        </th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="!element?.is_deleted">
            <button mat-icon-button matTooltip="Accept image" color="primary" (click)="acceptInstrumentFile(element)" [ngClass]="{'accepted-icon': element.is_approved}">
              <i [ngClass]="element.is_approved ? 'accepted-icon' : 'not-accepted-icon'"></i>
            </button>
            <button mat-icon-button matTooltip="Delete image"  color="warn" (click)="deleteInstrumentFile(element)">
              <img src="assets/images/decline-photo.svg" alt="">
            </button>
          </ng-container>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns3"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns3;"></tr>
    </table>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="tableValue==='notes'">
  <div class="toolbar" fxLayout="row" fxLayoutAlign="space-between center">
    <div
      class="toolbar-filter w100"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="10px"
    >
      <div class="grid w100">
        <div class="right">
          <div class="first-row">
            <span class="teamName" *ngIf="organizationsService.organization?.name">{{ organizationsService.organization?.name }} Note Queue</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="loading-shade" *ngIf="loading">
    <mat-spinner *ngIf="loading"></mat-spinner>
  </div>
  <div class="w100">
    <div
      class="table-container no-filters"
      infiniteScroll
      [infiniteScrollDistance]="1"
      [infiniteScrollThrottle]="300"
      (scrolled)="onScroll()"
      [scrollWindow]="false"
    >
      <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)"
             class="mat-elevation-z8">
        <ng-container matColumnDef="name">
          <th mat-header-cell class="w-700" *matHeaderCellDef mat-sort-header >
            Instrument
          </th>
          <td mat-cell class="w-700" *matCellDef="let element" > <img src="assets/images/no-image.svg" class="table-image" alt="instrument"> {{element.name}}</td>
        </ng-container>
        <ng-container matColumnDef="vendor__name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header >
            Manufacturer
          </th>
          <td mat-cell *matCellDef="let element" ><span class="manufacturer-outline">{{element.vendor.name}}</span></td>
        </ng-container>
        <ng-container matColumnDef="sku">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            SKU
          </th>
          <td mat-cell *matCellDef="let element">{{element.sku}}</td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Status
          </th>
          <td mat-cell *matCellDef="let element">{{statusMapping(element.status)}} </td>
        </ng-container>
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header >
            Date
          </th>
          <td mat-cell *matCellDef="let element" >{{element.date_created |date:'MM-dd-yy'}}</td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th class="w-170" mat-header-cell *matHeaderCellDef >
            Actions
          </th>
          <td class="w-170" mat-cell *matCellDef="let element">
            <button mat-button matTooltip="Open note editor" color="primary" (click)="openUpdateCatalog(element, 1)">
              <span class="review-note"> <img src="assets/icons/review-note.svg" alt="note"> Review note</span>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns4"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns4;"></tr>
      </table>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="tableValue==='cv'">
  <div class="toolbar" fxLayout="row" fxLayoutAlign="space-between center">
    <div
      class="toolbar-filter w100"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="10px"
    >
      <div class="grid w100">
        <div class="right">
          <div class="first-row">
            <span class="teamName" *ngIf="organizationsService.organization?.name">{{ organizationsService.organization?.name }} CV Queue</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="loading-shade" *ngIf="loading">
    <mat-spinner *ngIf="loading"></mat-spinner>
  </div>
  <div class="w100">
    <div
      class="table-container no-filters"
      infiniteScroll
      [infiniteScrollDistance]="1"
      [infiniteScrollThrottle]="300"
      (scrolled)="onScroll()"
      [scrollWindow]="false"
    >
      <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)"
             class="mat-elevation-z8">
        <ng-container matColumnDef="name">
          <th mat-header-cell class="w-600" *matHeaderCellDef mat-sort-header >
            Instrument
          </th>
          <td mat-cell class="w-600" *matCellDef="let element" >
            <img src="assets/images/no-image.svg" class="table-image" alt="instrument" *ngIf="!element?.mainImage">
            <img [src]="element?.mainImage" class="table-image" alt="instrument" *ngIf="element?.mainImage">
            {{element?.name}}
          </td>
        </ng-container>
        <ng-container matColumnDef="vendor__name">
          <th mat-header-cell *matHeaderCellDef>
            Manufacturer
          </th>
          <td mat-cell *matCellDef="let element" ><span class="manufacturer-outline">{{element.vendor}}</span></td>
        </ng-container>
        <ng-container matColumnDef="sku">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            SKU
          </th>
          <td mat-cell *matCellDef="let element">{{element.sku}}</td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>
            Status
          </th>
          <td mat-cell *matCellDef="let element">{{statusMapping(element.status)}} </td>
        </ng-container>
        <ng-container matColumnDef="created_date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header >
            Date
          </th>
          <td mat-cell *matCellDef="let element" >{{element.created_date |date:'MM-dd-yy'}}</td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th class="w-170" mat-header-cell *matHeaderCellDef >
            Actions
          </th>
          <td class="w-170" mat-cell *matCellDef="let element">
            <button mat-button matTooltip="Open captures editor" color="primary" [disabled]="element.isLoading" (click)="openUpdateCatalog(element, 2)">
              <span class="review-note" [ngClass]="{'loading-btn': element.isLoading}">
                <mat-spinner diameter="24" *ngIf="element.isLoading"></mat-spinner>
                <img src="assets/icons/review-note.svg" alt="note" *ngIf="!element.isLoading">
                View Captures</span>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns5"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns5;"></tr>
      </table>
    </div>
  </div>
</ng-container>
</div>
