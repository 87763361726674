import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";
import {InstrumentCaptureService} from "../../../services/instrument-capture/instrument-capture.service";
import {OrganizationsService} from "../../../services/organizations/organizations.service";
import {DialogService} from "../../../services/dialog/dialog.service";

@Component({
  selector: 'app-cv-library',
  templateUrl: './cv-library.component.html',
  styleUrls: ['./cv-library.component.scss']
})
export class CvLibraryComponent implements OnInit
{

  @Input() instrumentData:any;
  @Output() updateForm = new EventEmitter<any>();

  orgList: any = [];
  activeOrg: any = null;

  cvFiles: any[] = [];
  approvedList: any[] = [];
  awaitingList: any[] = [];
  rejectedList: any[] = [];
  isLoading = false;

  constructor(
    private readonly cvService: InstrumentCaptureService,
    public organizationsService: OrganizationsService,
    private readonly customDialog: DialogService
  ) { }

  ngOnInit() {
    this.activeOrg = this.organizationsService.getOrganizationUuid();
    this.getOrgList();
    this.getCaptures(this.activeOrg);
  }

  setActiveOrg(row: any) {
    this.activeOrg = row.uuid;
    this.getCaptures(this.activeOrg);
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
      if (event.container.id === 'approvedList') {
        this.approvedList = [...event.container.data];
        this.updateCaptureFileStatus(event.item.data, 2);
      } else if (event.container.id === 'awaitingList'){
        this.awaitingList = [...event.container.data];
        this.updateCaptureFileStatus(event.item.data, 1);
      } else if (event.container.id === 'rejectedList') {
        this.rejectedList = [...event.container.data];
        this.updateCaptureFileStatus(event.item.data, 3);
      }
    }
  }

  getOrgList() {
    this.organizationsService.getOrganizationsPerInst(this.instrumentData.data.uuid).subscribe((res: any) => {
      this.orgList = res?.results ?? [];
    });
  }

  updateCVList() {
    this.approvedList = [...this.cvFiles.filter((r: any) => r.status === "2")];
    this.awaitingList = [...this.cvFiles.filter((r: any) => r.status === "1")];
    this.rejectedList = [...this.cvFiles.filter((r: any) => r.status === "3")];
  }

  clearCVList() {
    this.cvFiles = [];
    this.approvedList = [];
    this.awaitingList = [];
    this.rejectedList = [];
  }

  updateCaptureFileStatus(data: any, status: number) {
    this.cvService.updateFile(data.captured_medical_instrument, data.id, this.activeOrg,{status: status}).subscribe();
  }

  getCaptures(orgUUID: string) {
    this.clearCVList();
    this.cvService.getFiles(this.instrumentData.data.id, orgUUID).subscribe( (res:any) => {
        this.cvFiles = res;
        this.updateCVList();
    });
  }

  dragRowStart(row: any) {
    row.dragging = true;
  }

  dragRowEnd(row: any) {
    setTimeout(() => {
      row.dragging = false;
    }, 50);
  }

  showPreview(row: any) {
    if(!row?.dragging) {
      this.customDialog.imagePreviewDialog(row.url).subscribe();
    }
  }

  reprocess() {
    this.isLoading = true;
    const listCaptureIDs = [...new Set(this.approvedList.map((r: any) => r.captured_medical_instrument))];
    if(listCaptureIDs.length > 0) {
      this.cvService.reprocessInst(listCaptureIDs).subscribe(() => {
        this.isLoading = false;
        this.updateForm.emit();
      }, () => {
        this.isLoading = false;
      });
    } else {
      this.isLoading = false;
      this.updateForm.emit();
    }
  }

}
