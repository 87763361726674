import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GeneralHelpers } from '../../helpers/general/general.helper';
import {API_BASE_V2, CATALOG} from '../../constants/general.constants';
import { Observable } from 'rxjs';
import { TrayNoteDTO, TrayNote, TrayNoteResponse } from '../../interfaces/tray-note.interface';

const TRAY = 'tray';
const NOTE = 'note'
const SECTION = 'section';

@Injectable({
  providedIn: 'root'
})
export class TraySectionNoteService {

  constructor(private http: HttpClient) { }

  // ────────────────────────────────────────────────────────────────────────────────
  // Create organization note

  create(data: TrayNoteDTO): Observable<any> {
    let { trayUuid, traySectionUuid, body } = data;
    (body as any) = GeneralHelpers.toMultipartFormData(body);
    return this.http.post(
      `${API_BASE_V2}${CATALOG}/${TRAY}/${trayUuid}/${SECTION}/${traySectionUuid}/${NOTE}/`,
      body
    );
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Read

  get(
    trayUuid: string,
    traySectionUuid: string,
    offset: number = 0,
    limit: number = 25,
    ordering: string = '',
    search: string = '',
  ): Observable<TrayNoteResponse> {
    const options = {
      params: GeneralHelpers.getParams({ offset, limit, ordering, search }),
    };
    return this.http.get<TrayNoteResponse>(
      `${API_BASE_V2}${CATALOG}/${TRAY}/${trayUuid}/${SECTION}/${traySectionUuid}/${NOTE}/`, options
    );
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Update

  update(data: TrayNoteDTO): Observable<Object> {
    let { trayUuid, traySectionUuid, noteUuid, body } = data;
    (data as any) = GeneralHelpers.toMultipartFormData(body);
    return this.http
      .put<TrayNote>(`${API_BASE_V2}${CATALOG}/${TRAY}/${trayUuid}/${SECTION}/${traySectionUuid}/${NOTE}/${noteUuid}/`, body);
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Delete

  delete(trayUuid: string, traySectionUuid: string, noteUuid: string) {
    return this.http.delete(
      `${API_BASE_V2}${CATALOG}/${TRAY}/${trayUuid}/${SECTION}/${traySectionUuid}/${NOTE}/${noteUuid}/`
    );
  }

}
