<div *ngIf="messageShown === false">
  <div class="header">
    <h2 mat-dialog-title>{{ data.title }}</h2>
    <button mat-icon-button [mat-dialog-close]="false" *ngIf="data.hasClose">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div mat-dialog-content>
    <p [innerHtml]="data.message"></p>
    <mat-form-field appearance="fill" class="w100">
      <mat-label>Tray’s name</mat-label>
      <input matInput placeholder="Name" [(ngModel)]="trayNameForDeletion" />
    </mat-form-field>
  </div>

  <div
    mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="mb-0 mt-3"
  >
    <button
      mat-button
      class="tray-button"
      [ngClass]="{
        grey: data.dialogData?.tray?.name !== trayNameForDeletion,
        red: data.dialogData?.tray?.name === trayNameForDeletion
      }"
      [disabled]="data.dialogData?.tray?.name !== trayNameForDeletion"
      aria-label="Delete"
      [mat-dialog-close]="data"
      (click)="showDeleteMessage()"
    >
      {{ data.confirmCaption }}
    </button>
    <button
      mat-button
      class="tray-button transparent"
      [mat-dialog-close]="false"
      *ngIf="data.cancelCaption"
    >
      {{ data.cancelCaption }}
    </button>
  </div>
</div>
<div *ngIf="messageShown === true">
  <div class="header">
    <h2 mat-dialog-title>Tray deleted successfuly</h2>
  </div>

  <div mat-dialog-content>
    <div class="delete-success"></div>
  </div>

  <div
    mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="center center"
    class="mb-0 mt-3"
  >
    <button
      mat-button
      class="tray-button blue"
      aria-label="Ok"
      (click)="close()"
      [mat-dialog-close]="data"
    >
      Ok
    </button>
  </div>
</div>
