import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ORGANIZATION_UUID } from "../constants/general.constants";

const catalogTrayV1 = /\/api\/1.0\/catalog\/tray(.*)/;
const catalogV2Hub = /\/api\/v2(.*)/;
const userAuth0 = /\/api\/1.0\/user\/auth0(.*)/;
const capturesV2Hub = /\/api\/v2\/hub\/catalog\/capture\/instrument(.*)/;

@Injectable()
export class OrganizationHeaderInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(this.patch(request));
  }

  private patch(request: HttpRequest<any>) {
    const orgUuid = localStorage.getItem(ORGANIZATION_UUID);
    const orgUUIDfromHeaders = request.headers.get('LJ-User-Organization');

    if(orgUUIDfromHeaders && capturesV2Hub.test(request.url)) {
      return request.clone({
        headers: request.headers.set('LJ-User-Organization', orgUUIDfromHeaders as string),
      });
    }

    if (orgUuid && catalogV2Hub.test(request.url)) {
      return request.clone({
        headers: request.headers.set('LJ-User-Organization', orgUuid as string),
      });
    }

    if (orgUuid && catalogTrayV1.test(request.url)) {
      return request.clone({
        headers: request.headers.set('LJ-User-Organization', orgUuid as string),
      });
    }

    if (orgUuid && userAuth0.test(request.url)) {
      return request.clone({
        headers: request.headers.set('LJ-User-Organization', orgUuid as string),
      });
    }
    return request;
  }
}
