<mat-card
  appearance="outlined"
  class="mat-elevation-z0 np"
  fxLayout="column"
  fxLayoutAlign="start center"
>
  <div fxLayout="row" fxLayoutAlign="space-between center" class="w100">
    <div>
      <mat-card-header>
        <mat-card-title fxLayout="column" fxLayoutAlign="start center">
          {{ "INSTRUMENT_CONTRIBUTION.TITLE" | translate }}
        </mat-card-title>
        <mat-card-subtitle>
          {{
            "INSTRUMENT_CONTRIBUTION.SUBTITLE" | translate
          }}</mat-card-subtitle
        >
      </mat-card-header>
    </div>
    <div class="alert alert-warning" role="alert">
      {{ "INSTRUMENT_CONTRIBUTION.OFFENSIVE" | translate }}
    </div>
  </div>

  <div class="mt-2">
    <app-instrument-form workMode="create" [afterSubmitMessage]="afterSubmitMessage"></app-instrument-form>
  </div>
</mat-card>
