import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ERROR_MESSAGE_CLASS,
  SUCCESS_MESSAGE_VERTICAL_POSITION,
  ERROR_MESSAGE_HORIZONTAL_POSITION,
  SUCCESS_MESSAGE_CLASS,
  MESSAGE_TIME,
} from '../../constants/general.constants';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  constructor(private matSnackBar: MatSnackBar) { }

  private subject = new Subject<unknown>();
  /**
   * @param  {string} message
   * @param  {any} data?
   * @returns void
   */
  sendMessage(message: string, data?: unknown): void {
    this.subject.next({ text: message, data });
  }
  /**
   * @returns Observable
   */
  getMessage(): Observable<unknown> {
    return this.subject.asObservable();
  }

  showMessage(
    kind: 'error' | 'success',
    title: string,
    actionText: string = 'Close'
  ) {
    this.matSnackBar.open(title, actionText, {
      duration: MESSAGE_TIME,
      horizontalPosition: ERROR_MESSAGE_HORIZONTAL_POSITION,
      verticalPosition: SUCCESS_MESSAGE_VERTICAL_POSITION,
      panelClass:
        kind === 'error' ? ERROR_MESSAGE_CLASS : SUCCESS_MESSAGE_CLASS,
    });
  }
}
